
export const questionTypes = {
  single_answer: "single_answer",
  multi_answer: "multi_answer",
}

export const questionTypesText = {
  single_answer: "Выбери один вариант ответа",
  multi_answer: "Выбери один или несколько вариантов ответа",
}
