import {onBeforeUnmount, onMounted, onUnmounted} from 'vue'

// todo: usedCount
let usedCount = 0

export function useModalHomeLayoutFix() {

  function open(el) {
    document.body.classList.add('hideHomeLayout')
    let current = el
    while (current !== document && current && !current.classList.contains('home_layout')) {
      current.classList.add('showFixHomeLayout')
      current = current.parentNode
    }
  }

  function close() {
    document.body.classList.remove('hideHomeLayout')
    const elems = document.querySelectorAll('.showFixHomeLayout')
    elems.forEach(el => el.classList.remove('showFixHomeLayout'))
  }

  onBeforeUnmount(() => {
    close()
  })

  return {
    open,
    close,
  }
}
