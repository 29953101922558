<template>
  <div style="margin-bottom: 140px">
    <Typography
        variant="h2"
        style="margin-bottom: 24px"
    >
      Личные данные
    </Typography>

    <FormTextInput
        name="first_name"
        label="Имя"
    />

    <FormTextInput
        name="last_name"
        label="Фамилия"
    />

    <FormTextInput
        name="email"
        label="Электронная почта"
    />

    <FormSelect
        name="city"
        label="Город"
        :options="state.cityList"
    />

    <div
        style="padding: 0 16px; background: #171717; border-top: 1px solid #2B2B2B; position: fixed; bottom: 0; left: 0; right: 0;"
    >
      <Typography
          style="margin: 24px 0 24px 0"
          variant="v14-600"
          center
      >
        Возникли сложности?
        <AppLink
            color="#FF303A"
            @click="openExternalURL(store.state.user.directories.support_chat_link.link)"
        >
          Напишите боту
        </AppLink>
      </Typography>

      <AppButton
          style="margin-top: 8px; margin-bottom: 24px"
          size="large"
          full-width
          @click="onSubmit"
          :loading="isSubmitting"
          :disabled="isSubmitting"
      >
        Продолжить
      </AppButton>
    </div>
  </div>
</template>

<script setup>
import Typography from '@/components/UI/Typography.vue'
import AppButton from '@/components/UI/AppButton.vue'
import FormTextInput from '@/components/form/FormTextInput.vue'
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import store from '@/store/store.js'
import {nextTick, onMounted, reactive} from 'vue'
import FormSelect from '@/components/form/FormSelect.vue'
import AppLink from "@/components/AppLink.vue"
import {openExternalURL} from "@/utils/openExternalURL.js"

const props = defineProps({
  nextStep: {
    type: Function,
    require: true,
  },
  createUserStepIfNotExist: {
    type: Function,
    require: true,
  },
})

const state = reactive({
  cityList: [],
})

const {handleSubmit, setErrors, isSubmitting, setFieldValue, setValues, setFieldError} = useForm({
  validationSchema: Yup.object({
    first_name: Yup.string().label('Имя').required(),
    last_name: Yup.string().label('Фамилия').required(),
    email: Yup.string().label('Электронная почта').required().email(),
    city: Yup.string().label('Город').required(),
  }),
})

onMounted(async () => {
  const {profile} = store.state.user
  if (profile.first_name || profile.last_name || profile.email || profile.city) {
    setValues({
      first_name: store.state.user.profile.first_name,
      last_name: store.state.user.profile.last_name,
      email: store.state.user.profile.email,
      city: store.state.user.profile.city,
    })
  }
  state.cityList = store.state.user.directories.city_list.map(city => {
    return {
      value: city,
      label: city,
    }
  })
})

const onSubmit = handleSubmit(async values => {
  const payload = {
    ...values,
  }
  await store.dispatch('user/updateProfile', payload)
  await props.nextStep()
})

</script>

<style lang="scss" scoped>

</style>
