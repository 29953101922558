export function getCourseProgressText(progress) {
  let passed = 0
  let count = 0
  let word = ''
  if (progress.total_modules === 0 || progress.total_modules === undefined) {
    word = 'уроков'
    passed = progress.completed_lessons
    count = progress.total_lessons
  } else {
    word = 'модулей'
    passed = progress.completed_modules
    count = progress.total_modules
  }
  return `${passed} из ${count} ${word} пройдено`
}

export function getCourseProgressPercent(progress) {
  if (progress.total_modules === 0 || progress.total_modules === undefined) {
    return Number(progress.completed_lessons_percent) / 100
  } else {
    return Number(progress.completed_modules_percent) / 100
  }
}
