<template>
  <div class="card_holder">
    <head-new
        page_title="Найти своего человека"
        :back-action="() => $router.push({name: 'SocialBrowsing'})"
        :rightAction="targetIsVisible || state.isLoading ? null : openSearch"
        :rightIcon="require(`@/assets/icons/white_24_search.svg`)"
    />

    <bottom-drawer
        v-if="!state.isLoading"
        :isShown="state.userContactShown"
        title="Удаление из визитницы"
        @close="state.userContactShown = false"
    >
      <template v-if="state.userContactShown">
        <!--        <user-card-->
        <!--            :data="state.userContactShown.contact_user"-->
        <!--            hideActions-->
        <!--            style="margin-bottom: 16px"-->
        <!--        />-->
        <!--        <Evalution-->
        <!--            @evaluated="evaluated"-->
        <!--            :user="state.userContactShown.contact_user"-->
        <!--            :userContact="state.userContactShown"-->
        <!--        />-->
        <Typography variant="v14-600" color="#D3D2D2">
          Ты действительно хочешь удалить
          {{ state.userContactShown.contact_user.first_name }} {{ state.userContactShown.contact_user.last_name }}
          из визитницы?
        </Typography>

        <div style="display: flex; gap: 4px; margin: 16px 0">
          <appButton
              full-width
              class="submitButton"
              :disabled="false"
              variant="secondary"
              @click="state.userContactShown = false"
          >
            Отменить
          </appButton>
          <appButton
              full-width
              class="submitButton"
              :loading="false"
              :disabled="false"
              variant="secondary"
              color="#FF303A"
              @click="() => evaluated(0, state.userContactShown)"
          >
            Удалить
          </appButton>
        </div>
      </template>
    </bottom-drawer>

    <RecycleScroller
        v-if="state.contactList.length"
        class="main_container holder_in"
        :items="state.contactList"
        :item-size="264"
        key-field="indexKey"
        :buffer="264"
    >
      <template #before>
        <div class="search" ref="refSearch">
          <FormTextInputSearch
              leftIcon="`@/assets/icons/gray_24_search.svg`"
              name="searchQuery"
              placeholder="Ключевые слова"
              fullWidth
              onlyInput
              @focus="openSearch"
              @options="openSearch({filter: true})"
          />
        </div>
      </template>

      <template #after>
        <div style="height: 16px"/>
      </template>

      <template v-slot="{item}">
        <UserCard
            :data="item.user ?? item.contact_user"
            :userContact="item"
            style="margin-bottom: 4px"
            :profileClick="() => {
              if(item.user) {
                router.push({name: 'BrowsingUserProfile', params: {userId: item.user.id}})
              } else {
                router.push({name: 'BrowsingUserProfile', params: {userId: item.contact_user.id}})
              }
            }"
            @reEvaluate="contact => state.userContactShown = contact"
            @addContact="addToContact"
        />
      </template>
    </RecycleScroller>

    <div class="main_container holder_in">
      <template v-if="!state.isLoading">
        <div
            v-if="state.contactList.length === 0"
            class="listIsEmpty"
        >
          <div class="userEmptyCards">
            <div class="userFakeCard"/>
            <div class="userFakeCard"/>
            <UserCard
                :is-loading="true"
                :data="null"
                hideActions
            />
          </div>

          <Typography variant="subtitle" center style="padding: 0 12px">
            Визитница пуста
          </Typography>

          <Typography variant="body1" center color="#D3D2D2" style="padding: 0 12px">
            Начинай нетворкинг, добавляй пользователей и создавай окружение своих людей!
          </Typography>

          <AppButton
              style="margin-top: 24px"
              @click="router.push({name: 'CardHolderSocialBrowsing'})"
          >
            Нетворкать
          </AppButton>
        </div>
      </template>

      <template v-if="state.isLoading">
        <div class="search">
          <Skeleton
              height="48"
              radius="12"
              full-width
          />
        </div>
        <div style="display: flex; flex-direction: column; gap: 16px;">
          <UserCard
              v-if="state.isLoading"
              v-for="i in 5"
              :data="{}"
              :isLoading="true"
              :userContact="true"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import {computed, onBeforeMount, reactive, ref, toRaw} from 'vue'
import {appAxios} from '@/axios'
import {router} from '@/router/router'
import UserCard from '@/components/UserCard.vue'
import Evalution from '@/components/Evaluation.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import Typography from '@/components/UI/Typography.vue'
import AppButton from '@/components/UI/AppButton.vue'
import {useElementVisibility} from '@vueuse/core'
import FormTextInputSearch from "@/components/form/FormTextInputSearch.vue"
import Skeleton from "@/components/UI/Skeleton.vue"

const refSearch = ref(null)
const targetIsVisible = useElementVisibility(refSearch)

const state = reactive({
  isLoading: true,
  userContactShown: false,

  user: null,
  contactList: [],
})

function close() {
  // router.replace({name: 'CardHolder'})
}

onBeforeMount(async () => {
  try {
    const res = await appAxios.user.searchPaginated({}, 10, 0)
    res.data.results.forEach((item, i) => item.indexKey = i)
    state.contactList = res.data.results
  } catch (e) {
    console.log('error', e)
  } finally {
    state.isLoading = false
  }
})

function openSearch(params = {}) {
  const {filter} = params
  router.push({name: 'CardHolderSearch', params: {filter}})
}

async function evaluated(rate, userContact) {
  if (rate === 0) {
    await appAxios.myContacts.removeContact(userContact.id)
    const found1 = state.contactList.find(c => c.id === userContact.id)

    Object.assign(found1, {
      user: found1.contact_user ?? found1.user,
      id: null,
      user_id: null,
      rating: null,
      contact_user: null,
      isLoading: false,
    })
  }
  state.userContactShown = false
}


async function addToContact(user) {
  const found1 = state.contactList.find(uc => uc.user?.id === user.id || uc.contact_user?.id === user.id)
  found1.isLoading = true
  const res = await appAxios.myContacts.addContact(user.id, 5)

  Object.assign(found1, {
    user: null,
    ...res.data,
  })

  found1.isLoading = false
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.card_holder {
  margin-bottom: -$homeLayoutPaddingBottom;
  min-height: calc(100dvh - $bottomMenuOffset);
  max-height: calc(100dvh - $bottomMenuOffset);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.holder_modal_active {
  opacity: 1;
  z-index: 101;

  .holder_modal_in {
    transform: translateY(0);
  }
}

.listIsEmpty {
  margin-top: 96px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 60px;
}

.userFakeCard {
  padding: 16px;
  border-radius: 20px;
  border: 1px solid #FFFFFF14;
  background: #222222;
  height: 220px;
}

.userEmptyCards {
  margin-bottom: 32px;
  position: relative;

  & > *:nth-child(1) {
    position: absolute;
    height: 100%;
    width: 100%;
    top: -48px;
    transform: scale(0.8);
    z-index: 1;
    background: #1B1B1B;
  }

  & > *:nth-child(2) {
    position: absolute;
    height: 100%;
    width: 100%;
    top: -24px;
    transform: scale(0.9);
    z-index: 2;
    background: #1F1F1F;
  }

  & > *:nth-child(3) {
    position: relative;
    z-index: 3;
  }
}

.search {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
}

</style>
