export function copyToClipboard(text, event) {
  if(event && event.target) {
    event.target.classList.add('copied')
    setTimeout(() => {
      try {
        event.target.classList.remove('copied')
      } catch (e) {
        //
      }
    }, 500)
  }
  navigator.clipboard.writeText(text)
}
