<template>
  <div class="edit_profile">
    <HeadNew
        :page_title="route.meta.isCheckList ? 'Фотография профиля' : 'Редактировать профиль'"
        :back-action="() => router.push({name: $route.meta.returnName ?? 'Profile'})"
    />

    <div class="main_container takePhone">
      <div
          style="position: relative"
      >
        <FormImageAvatar
            name="avatar"
            :image-preview="state.imagePreview"
        />
      </div>
    </div>

    <div v-if="!route.meta.isCheckList" class="main_container">
      <Typography variant="body1" center>
        Фотография профиля
      </Typography>
    </div>

    <div v-if="!route.meta.isCheckList" class="main_container container">
      <div class="menuItems">
        <MenuItem
            text="Личные данные"
            :icon="require(`@/assets/icons/gray_24_profile.svg`)"
            :action="() => router.push({name: route.meta.isCheckList ? 'CheckListEditPersonalData' : 'EditPersonalData'})"
        />
        <MenuItem
            text="О себе"
            :icon="require(`@/assets/icons/gray_24_penEdit.svg`)"
            :action="() => router.push({name: route.meta.isCheckList ? 'CheckListEditDescription' : 'EditDescription'})"
        />
        <MenuItem
            text="Контакты"
            :icon="require(`@/assets/icons/gray_24_phone.svg`)"
            :action="() => router.push({name: route.meta.isCheckList ? 'CheckListEditContacts' : 'EditContacts'})"
        />
        <MenuItem
            text="Роль"
            :icon="require(`@/assets/icons/gray_24_userProfile.svg`)"
            :action="() => router.push({name: route.meta.isCheckList ? 'CheckListEditProfessions' : 'EditProfessions'})"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import MenuItem from '@/components/UI/MenuItem.vue'
import {router} from '@/router/router'
import HeadNew from '@/components/Head.vue'
import store from '@/store/store'
import {urlToCssURL} from '@/utils/urlToCssURL'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import {onBeforeMount, reactive, watch} from 'vue'
import Typography from '@/components/UI/Typography.vue'
import ImageCropper from "@/components/UI/ImageCropper.vue";
import {useRoute} from "vue-router";
import FormImageAvatar from "@/components/form/FormImageAvatar.vue";
import {useForm} from "vee-validate";
import * as Yup from "yup";
import {resizeImage} from "@/utils/resizeImage.js";

const route = useRoute()

const state = reactive({
  file: false,
  newAvatar: '',
  filesToCrop: [],
  imagePreview: null,
  profile: null,
})

const {handleSubmit, setErrors, isSubmitting, setFieldValue, setValues, values} = useForm({
  validationSchema: Yup.object({
    avatar: Yup.mixed()
        .test('required', 'Загрузите фотографию', (value) => state.imagePreview || value)
    // .test('Size', 'File size must be less than 20kb', (value) => value && value?.size <= 500)
  }),
})

onBeforeMount(() => {
  const {profile} = store.state.user
  state.profile = profile

  if (store.state.user.profile.avatar) {
    state.imagePreview = store.state.user.profile.avatar
  }
})

const onSubmit = handleSubmit(async values => {
  if (values.avatar) {
    await store.dispatch('user/updateProfile', {avatar: values.avatar})
  }
})

watch(values, values => {
  if (values.avatar) {
    onSubmit()
  }
})

async function onCrop(rawFile) {
  const blob = await resizeImage({
    file: rawFile,
    maxSize: 1080,
  })
  const file = new File([blob], rawFile.name)

  state.file = file

  const payload = {}
  if (state.file) {
    payload.avatar = state.file
  }
  await store.dispatch('user/updateProfile', payload)
  state.newAvatar = URL.createObjectURL(file)
}

</script>

<style lang="scss" scoped>

label {
  display: block;
}

.takePhone.takePhone {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 12px;
}

.userAvatar {
  position: absolute;
  z-index: -2;
  width: 106px;
  height: 106px;
  border-radius: 16px;
  background-size: cover;
  background-repeat: no-repeat;
}

.userAvatarUpdate {
  position: absolute;
  z-index: -1;
  width: 106px;
  height: 106px;
  border-radius: 16px;
  background-size: cover;
  background-repeat: no-repeat;
}

.edit_img {
  display: block;
  cursor: pointer;

  .profileImage {
    width: 106px;
    height: 106px;
    background-image: url("@/assets/images/profile/takePhoto.svg");

    .empty {
      background-image: url("@/assets/images/profile/takePhotoEmpty.svg");
    }
  }

  #user_img {
    display: none;
  }
}

//

.container.container {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.edit_profile {
  .bcg_gray {
    margin-top: 0;
    padding: 16px;
    border-radius: 20px;
    background: #222;
    margin-bottom: 4px;

    .bcg_gray_top {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-bottom: 12px;

      span {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }

  .profil_about {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  .profile_delete_btn {
    padding: 10px 24px 10px 24px;
    border-radius: 12px;
    color: rgb(255, 48, 58);
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0;
    text-align: center;
  }

  .delete_modal {
    height: 100%;
    width: 100%;
    backdrop-filter: blur(13.5px);
    transform: translate3d(0, 0, 0);
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: end;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    transition: 0.5s;

    .confirmation_request {
      max-width: 420px;
      width: 100%;
      border-radius: 16px 16px 0px 0px;
      background: rgb(34, 34, 34);
      padding: 16px 16px 55px;
      transform: translateY(259px);
      transition: 0.5s;

      .modal_top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgb(255, 255, 255);
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0;
        margin-bottom: 12px;
      }

      .description {
        color: rgb(211, 210, 210);
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 20px;
      }
    }

    .caution {
      max-width: 420px;
      width: calc(100% - 32px);
      height: 100%;
      max-height: 301px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      transform: scale(0.8);
      transform-origin: center;
      padding: 8px 16px 16px;
      border-radius: 24px;
      background: rgb(34, 34, 34);
      text-align: center;
      opacity: 0;
      z-index: -1;
      transition: 0.3s;

      .close_btn {
        position: absolute;
        top: 12px;
        right: 12px;
      }

      .title {
        color: rgb(255, 255, 255);
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0;
        text-align: center;
        margin: 8px 0 12px;
      }

      .description {
        color: rgb(211, 210, 210);
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0;
        text-align: center;
        margin-bottom: 37px;
      }
    }

    .caution_active {
      transform: scale(1);
      opacity: 1;
      z-index: 2;
    }

    .delete_accaunt {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
      width: 100%;
      height: 100%;
      background: rgb(23, 23, 23);
      padding: 48px 16px 52px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .center_block {
        text-align: center;

        .title {
          color: rgb(255, 255, 255);
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0;
          text-align: center;
          margin: 11px 0 8px;
        }

        .description {
          color: rgb(211, 210, 210);
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0;
          text-align: center;
        }
      }
    }

    .delete_accaunt_active {
      visibility: visible;
      opacity: 1;
      z-index: 3;

      .center_block {
        svg {
          animation: 1.2s svgScale;
        }

        @keyframes svgScale {
          0% {
            transform: translate(1);
          }
          50% {
            transform: scale(1.5);
          }
          100% {
            transform: translate(1);
          }
        }
      }
    }

    .delete_buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;

      button {
        display: block;
        border: 1px solid rgb(73, 73, 73);
        border-radius: 12px;
        padding: 10px;
        background: rgb(47, 47, 47);
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0;
        text-align: center;
      }

      .delete_btn {
        color: rgb(255, 48, 58);
        border-color: transparent;
      }

      .sign_up {
        background: rgb(207, 46, 46);
        border-color: transparent;
      }
    }

    .register_btns {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .modal_active {
    opacity: 1;
    z-index: 1001;

    .confirmation_request {
      transform: translateY(0);
    }
  }
}

.menuItems {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
}
</style>
