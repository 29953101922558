<template>
  <div class="splashScreen">
    <div class="logo" />
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

.splashScreen {
  background-color: #171717;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}

.logo {
  display: block;
  height: 90px;
  width: 200px;
  position: relative;
  background: url("@/assets/images/logo/white_logo_leadpay_128_58.svg") no-repeat;
  background-size: contain;

  &::after {
    content: "";
    width: 300px;
    height: 70px;
    filter: blur(100px);
    transform: translate3d(0, 0, 0);
    background: rgb(207, 46, 46);
    opacity: 0.5;
    position: absolute;
    top: 5px;
    left: -50px;
  }
}

img {
  user-select: none;
}
</style>
