export function requestDoubleAnimationFrame(fun) {
  return new Promise(res => {
    requestAnimationFrame(() => {
      requestAnimationFrame(async () => {
        res(await fun())
      })
    })
  })
}

export function everyTwoAnimationFrame(fun) {
  return new Promise(res => {
    requestAnimationFrame(async () => {
      fun()
      requestAnimationFrame(async () => {
        fun()
        res()
      })
    })
  })
}
