<template>
  <div class="main_container" v-if="store.state.app.info">

    <template v-if="pwaUtils.isAppleMobileOriginal() || pwaUtils.isAndroidMobileOriginal()">

      <Typography
          variant="v20-700"
          style="max-width: 280px; margin-bottom: 24px; margin-top: 24px;"
          center
      >
        Добавление платформы<br/>
        на экран «Домой»
      </Typography>

      <template v-if="platform === 'ios'">
        <Icon
            style="margin-top: 24px;flex-shrink: 0"
            width="280"
            height="224"
            :icon="require(`@/assets/images/system/install_pwa_ios_step1.svg`)"
        />

        <div style="margin-top: 16px" class="redDot">
          1
        </div>

        <Typography
            style="margin-top: 12px;margin-bottom: 32px;max-width: 280px"
            center
            variant="body2"
        >
          Нажми на иконку <span style="font-weight: 700">«Поделиться»</span> в нижнем меню браузера
        </Typography>

        <Icon
            style="margin-top: 24px;flex-shrink: 0"
            width="280"
            height="224"
            :icon="require(`@/assets/images/system/install_pwa_ios_step2.svg`)"
        />

        <div style="margin-top: 16px" class="redDot">
          2
        </div>

        <Typography
            style="margin-top: 12px;margin-bottom: 32px;max-width: 280px"
            center
            variant="body2"
        >
          Выбери пункт <span style="font-weight: 700">На экран «Домой»</span>, а после — <span
            style="font-weight: 700">«Добавить»</span>
        </Typography>

        <Icon
            style="margin-top: 24px;flex-shrink: 0"
            width="280"
            height="121"
            :icon="require(`@/assets/images/system/install_pwa_ios_step3.png`)"
        />

        <div style="margin-top: 16px" class="redDot">
          3
        </div>

        <Typography
            style="margin-top: 12px;margin-bottom: 32px;max-width: 280px"
            center
            variant="body2"
        >
          Теперь у тебя появилась иконка нашего приложения
        </Typography>

      </template>

      <template v-if="platform === 'android'">
        <Icon
            style="margin-top: 24px;flex-shrink: 0"
            width="256"
            height="81"
            :icon="require(`@/assets/images/system/install_pwa_android_step1.svg`)"
        />

        <div style="margin-top: 16px" class="redDot">
          1
        </div>

        <Typography
            style="margin-top: 12px;margin-bottom: 32px;max-width: 280px"
            center
            variant="body2"
        >
          Нажми на <span style="font-weight: 700">3 точки</span> в верхнем меню браузера
        </Typography>

        <Icon
            style="margin-top: 24px;flex-shrink: 0"
            width="256"
            height="137"
            :icon="require(`@/assets/images/system/install_pwa_android_step2.svg`)"
        />

        <div style="margin-top: 16px" class="redDot">
          2
        </div>

        <Typography
            style="margin-top: 12px;margin-bottom: 32px;max-width: 280px"
            center
            variant="body2"
        >
          Выбери пункт <span style="font-weight: 700">Добавить</span> на гл. экран, а после — <span
            style="font-weight: 700">«Добавить»</span>
        </Typography>

        <Icon
            style="margin-top: 24px;flex-shrink: 0"
            width="256"
            height="121"
            :icon="require(`@/assets/images/system/install_pwa_android_step3.png`)"
        />

        <div style="margin-top: 16px" class="redDot">
          3
        </div>

        <Typography
            style="margin-top: 12px;margin-bottom: 32px;max-width: 280px"
            center
            variant="body2"
        >
          Теперь у тебя появилась иконка нашего приложения
        </Typography>

      </template>
    </template>

    <template v-else-if="pwaUtils.isAppleMobile() || pwaUtils.isAndroidMobile()">
      <Typography
          variant="v20-700"
          style="max-width: 280px; margin-bottom: 24px; margin-top: 24px;"
          center
      >
        Установка платформы
      </Typography>

      <template v-if="platform === 'ios'">

        <Typography
            style="margin-top: 12px;margin-bottom: 32px;max-width: 280px; line-height: 20px"
            center
            variant="v14-400"
        >
          Откройте
          <a class="link" :href="location.href">{{ hostname }}</a>
          в
          <Icon
              style="background-size: cover; display: inline-block; vertical-align: bottom; margin: 0 6px;"
              width="20"
              height="20"
              :icon="require(`@/assets/images/system/install_pwa_ios_safari.png`)"
          />
          <span style="font-weight: 700">Safari.</span>
          Что делать дальше - расскажем там
        </Typography>

        <AppButton
            width="60"
            style="margin-bottom: 24px"
            @click="copyToClipboard(location.href, $event)"
        >
          Скопировать ссылку
        </AppButton>

      </template>

      <template v-if="platform === 'android'">

        <Typography
            style="margin-top: 12px;margin-bottom: 32px;max-width: 280px; line-height: 20px"
            center
            variant="v14-400"
        >
          Откройте
          <a class="link" :href="location.href">{{ hostname }}</a>
          в
          <Icon
              style="background-size: cover; display: inline-block; vertical-align: bottom; margin: 0 6px;"
              width="20"
              height="20"
              :icon="require(`@/assets/images/system/install_pwa_ios_android.png`)"
          />
          <span style="font-weight: 700">Chorme.</span>
          Что делать дальше - расскажем там
        </Typography>

        <AppButton
            width="60"
            style="margin-bottom: 24px"
            @click="copyToClipboard(location.href, $event)"
        >
          Скопировать ссылку
        </AppButton>

      </template>
    </template>

    <template v-else>
      <PlatformQR/>
    </template>

    <div
        style="margin-bottom: 24px"
        class="delimiter"
    />

    <Icon
        width="160"
        height="160"
        :icon="require(`@/assets/images/system/install_pwa_help.svg`)"
    />

    <Typography
        style="margin-top: 24px;margin-bottom: 32px;"
        center
        variant="v14-600"
    >
      Если возникли сложности, напиши в
      <AppLink
          color="#FF303A"
          @click="clickOpenURL(store.state.app.info.support_chat_link.link)"
      >
        наш бот
      </AppLink>
    </Typography>

  </div>
</template>

<script setup>
import {onBeforeMount} from 'vue'
import {pwaUtils} from '@/utils/pwaUtils.js'
import {router} from '@/router/router.js'
import Icon from '@/components/UI/Icon.vue'
import Typography from '@/components/UI/Typography.vue'
import AppButton from "@/components/UI/AppButton.vue";
import {copyToClipboard} from "@/utils/copyToClipboard.js";
import AppLink from "@/components/AppLink.vue";
import store from "@/store/store.js";
import PlatformQR from "@/views/system/PlatformQR.vue";

let platform = null
let browser = null

const hostname = window.location.hostname

const location = window.location

onBeforeMount(async () => {
  browser = pwaUtils.getBrowserName()
  if (pwaUtils.isStandalone()) {
    await router.push({name: 'Home'})
  }
  if (pwaUtils.isAppleMobile()) {
    platform = 'ios'
  }
  if (pwaUtils.isAndroidMobile()) {
    platform = 'android'
  }
  if (!platform) {
    await router.push({name: 'Home'})
  }
})

function clickOpenURL(url) {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: url,
  }).click();
}
</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  min-height: calc(100dvh - $bottomMenuOffset - 0px);
  max-height: calc(100dvh - $bottomMenuOffset - 0px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageWrapperOverflow {
  //padding-bottom: 32px + $bottomMenuOffset;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    flex-shrink: 0;
  }
}

.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    flex-shrink: 0;
  }
}

.redDot {
  width: 28px;
  height: 28px;
  border-radius: 24px;
  background: #CF2E2E;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delimiter {
  width: 100%;
  margin: 16px 0;
  border: 1px solid #2B2B2B;
}

.link {
  line-height: 20px;
  color: rgba(207, 46, 46, 1);
}

</style>
