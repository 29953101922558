<template>
  <div class="msg_wrapper">
    <button @click="$emit('update:show', false)" class="back_btn">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M5 12H19" stroke="#121111" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10 7L5 12" stroke="#121111" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10 17L5 12" stroke="#121111" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </button>
    <div class="auth_title">Введи код из SMS</div>
    <div class="phone">
      <label>Код подтверждения отправили на номер</label>
      <span>{{ phone }}</span>
<!--      <input :value="phone" v-maska="bindedObject" data-maska="+7(###) ###-##-##" disabled />-->
    </div>
    <div class="confirm_input_wrapper">
      <input
        v-for="i in 4"
        :key="i"
        type="text"
        v-maska
        data-maska="#"
        pattern="[0-9]*"
        inputmode="tel"
        :ref="`confirm_input_${i - 1}`"
        v-model="code[i - 1]"
        @keydown="handleKeyDown($event, i - 1, code[i - 1])"
        @input="handleKeyUp($event, i - 1, code[i - 1])"
        :class="error ? 'error_input' : ''"
      />
    </div>
    <div v-if="error" class="error_text">
      <img src="../../assets/images/error_input.svg" alt="" />
      {{errorMsg ? errorMsg : 'Неверный код, попробуй ещё раз'}}
    </div>
<!--    <div class="msg_time">Отправить код повторно через <span>0:23</span></div>-->
  </div>
</template>

<script>
import { vMaska } from "maska"
import * as libPhoneNumber from 'libphonenumber-js'

export default {
  name: "CheckMsg",
  directives: { maska: vMaska },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    msg_code: String,
    phone: String,
    error: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: '',
    }
  },
  emits: ["update:show", "update:msg_code"],
  data() {
    return {
      libPhoneNumber,
      bindedObject: {
        masked: "",
        unmasked: "",
        completed: false,
      },
      code: ["", "", "", ""],
    };
  },
  mounted() {
    this.$refs.confirm_input_0[0].focus();
  },
  methods: {
    handleKeyDown(event, idx, value) {
      if (event.key === "Tab") {
        event.preventDefault();
      }
      if (event.key === "Backspace") {
        if (!value && idx !== 0) {
          this.$refs[`confirm_input_${idx - 1}`][0].focus();
        }
      }
    },
    handleKeyUp(event, idx, value) {
      if (event.key !== "Backspace" && idx !== 3 && value.match(/\d+/g)) {
        this.$refs[`confirm_input_${idx + 1}`][0].focus();
      }
      this.$emit("update:msg_code", this.code.join(""));
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/variables";

.msg_wrapper {
  padding: 56px 16px 16px;
  .back_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }
  .error_text {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    color: rgb(255, 48, 58);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0%;
    text-align: left;
    background: transparent;
    padding: 0;
    margin-bottom: 0;
  }
}

.dark-theme .msg_wrapper .back_btn svg path {
  stroke: #fff;
}

.msg_wrapper .auth_title {
  text-align: center;
  margin-bottom: 12px;
}

.phone {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;

  label {
    color: #a6a5a5;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
  }

  input {
    display: none;
  }

  span {
    font-variant-numeric: lining-nums proportional-nums;
    color: rgb(166, 165, 165);
    font-family: Raleway, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0%;
    text-align: center;
  }
}

.confirm_input_wrapper {
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: flex-start;
  text-align: center;
  margin-bottom: 27px;
}

.confirm_input_wrapper input {
  width: 56px;
  height: 72px;
  text-align: center;
  color: #fff;
  border-bottom: 2px solid #4d4c4c;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

@keyframes inputAnimation {
  0% {
    border-bottom: 2px solid #cf2e2e;
  }
  50% {
    border-bottom: 2px solid transparent;
  }
  100% {
    border-bottom: 2px solid #cf2e2e;
  }
}

.confirm_input_wrapper input:focus {
  animation: 1.3s ease inputAnimation infinite;
}
.confirm_input_wrapper .error_input {
  color: rgb(255, 48, 58);
  border-bottom: 2px solid rgb(255, 48, 58);
}

.msg_time {
  color: #a6a5a5;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  margin-top: 40px;
}

.msg_time span {
  color: #cf2e2e;
}

.dark-theme .msg_time span {
  color: #fff;
}
</style>
