
export function useShare() {
  let canShare = false

  try {
    canShare = navigator.canShare({
      title: 'title',
      description: 'description',
      url: 'https://example.com/',
    })
  } catch (e) {
    canShare = false
  }

  async function share({title, description, url, files}) {
    try {
      await navigator.share({
        title,
        description,
        url,
        files,
      })
    } catch (e) {
      console.error(e)
    }
  }

  return {
    canShare,
    share,
  }
}
