<template>
  <div class="anons_block">

    <router-link :to="{ name: 'MarketplaceCourses' }" class="link">
      <div class="row">
        <span class="icon">
          <img src="../../assets/images/home/homeStudy.svg" alt=""/>
        </span>
        <span class="title">Обучение</span>
      </div>
      <div class="subtitle">
        <!--        Еще не учусь-->
        Каталог курсов
      </div>
    </router-link>

    <router-link :to="{ name: 'MarketplaceProducts' }" class="link">
      <div class="row">
        <span class="icon">
          <img src="../../assets/images/home/homeProducts.svg" alt=""/>
        </span>
        <span class="title">Ярмарка</span>
      </div>
      <div class="subtitle">Продукты экспертов</div>
    </router-link>

  </div>
</template>

<style lang="scss">

.row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.anons_block {
  //padding-top: 20px;
  padding-bottom: 8px;
  display: flex;
  align-items: stretch;
  gap: 8px;

  .link {
    width: calc(50% - 4px);
    border-radius: 20px;
    background: #222;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &:nth-child(2) {
      .icon {
        background: #38a0ff;
      }
    }
  }

  .icon {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #b61cff;
  }

  .title {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }

  .subtitle {
    color: #a6a5a5;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
  }
}
</style>
<script setup>
</script>
