import {appAxios} from "@/axios.js";

export const app = {
  namespaced: true,

  state: () => ({
    isOnline: true,
    isCrashed: false,
    isNewVersionAvailable: false,
    info: null,
  }),

  getters: {

  },

  mutations: {
    setInfo(state, info) {
      state.info = info
    },
    setIsOnline(state, isOnline) {
      state.isOnline = isOnline
    },
    setIsCrashed(state, isOnline) {
      state.isCrashed = isOnline
    },
    setIsNewVersionAvailable(state, isOnline) {
      state.isNewVersionAvailable = isOnline
    },
  },

  actions: {
    async init(context, payload) {
      const res = await appAxios.guest.fetchDirectories()
      context.commit('setInfo', res.data)
    },
    async setOnlineState(context, payload) {
      context.commit('setIsOnline', payload)
    },
    async setCrashedState(context, payload) {
      context.commit('setIsCrashed', payload)
    },
    async setIsNewVersionAvailable(context, payload) {
      context.commit('setIsNewVersionAvailable', payload)
    },
  }
}
