<template>
  <div
      v-if="data.video_url"
      class="videoWrapper"
      style="position: relative; padding-top: 56.25%; width: 100%"
      :data-uid="state.uid"
  >
    <div
        :id="state.uid"
    />
  </div>
  <div
      v-else
      class="videoWrapper"
  >
    <video
        ref="videoRef"
        :src="data.video_file"
        controls
        controlsList="nodownload"
        oncontextmenu="return false;"
        :poster="imagesLinkCache.cache(props.data.image)"
    />
    <div
        v-if="state.showPreview"
        class="preview"
        :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(props.data.image))}"
        @click="previewPlay"
    />
  </div>
</template>

<script setup>
import {imagesLinkCache} from '@/utils/imagesLinkCache.js'
import {urlToCssURL} from '@/utils/urlToCssURL.js'
import {onMounted, onUnmounted, reactive, ref} from 'vue'

const videoRef = ref(null)
const scriptRef = ref(null)

if (!window.kinescopePlayers) {
  window.kinescopePlayers = {}
}

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const state = reactive({
  showPreview: true,
  uid: Date.now().toString(36) + Math.random().toString(36).substr(2),
})

async function previewPlay() {
  if (props?.data?.video_url || !videoRef.value) {
    return
  }
  const video = videoRef.value
  state.showPreview = false
  await video.play()
}

onUnmounted(() => {
  if (scriptRef.value) {
    try {
      scriptRef.value.parentNode.removeChild(scriptRef.value)
    } catch (e) {
      console.log('e', e)
    }
  }
  if (window?.kinescopePlayers[state.uid]) {
    try {
      const player = window.kinescopePlayers[state.uid]
      player.destroy()
      delete window.kinescopePlayers[state.uid]
    } catch (e) {
      console.log('e', e)
    }
  }
})

function initPlayer(playerFactory) {
  playerFactory
      .create(state.uid, {
        url: props?.data?.video_url,
        // size: { width: '50%', height: 400 },
      })
      .then(function (player) {
        state.playerId = player.playerId

        player.iframe.classList.add('video')
        player.iframe.dataset.playerId = player.uid

        window.kinescopePlayers[state.uid] = player
      })
}

onMounted(async () => {
  if (props?.data?.video_url) {
    if (window.playerFactoryPromise) {
      initPlayer(await window.playerFactoryPromise)
      return
    }
    let promiseResolve = null
    window.playerFactoryPromise = new Promise((res) => {
      promiseResolve = res
    })

    const tag = document.createElement('script')
    tag.src = 'https://player.kinescope.io/v2.102.8/iframe.player.js'
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    scriptRef.value = tag

    function onKinescopeIframeAPIReady(playerFactory) {
      window.playerFactory = playerFactory
      promiseResolve(window.playerFactory)
      initPlayer(playerFactory)
    }

    window.onKinescopeIframeAPIReady = onKinescopeIframeAPIReady
  }
  if (props?.data?.video_url || !videoRef.value) {
    return
  }
  const video = videoRef.value
  video.addEventListener('play', (event) => {
    state.showPreview = false
  })
})

</script>

<style lang="scss" scoped>

.videoWrapper {
  position: relative;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #CF2E2E;
  overflow: hidden;
}

video {
  width: 100%;
  display: block;
}

.preview {
  position: absolute;
  top: -1px;
  left: -1px;
  //bottom: 50px;
  //right: 50px;
  bottom: -1px;
  right: -1px;
  display: block;
  border: 1px solid #CF2E2E;
  background-size: cover;
}

.preview:before {
  content: " ";
  position: absolute;
  display: block;
  background: #CF2E2E;
  width: 32px;
  height: 32px;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  border-radius: 24px;
}

.preview:after {
  content: " ";
  position: absolute;
  display: block;
  top: calc(50% - 9px);
  left: calc(50% - 3px);

  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 9px solid #FFF;
}

</style>

<style>
.videoWrapper {
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

</style>
