<template>
  <div class="auth_wrapper">
    <div class="auth_container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthDefault",
};
</script>

<style lang="scss">
.auth_container {
  max-width: 420px;
  margin: 0 auto;
}

.auth_head {
  margin-bottom: 20px;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &::after {
    content: "";
    width: 100%;
    height: 239px;
    filter: blur(100px);
    transform: translate3d(0, 0, 0);
    background: rgb(207, 46, 46);
    opacity: 0.08;
    position: absolute;
    top: -119px;
    left: 0;
  }
}
</style>
