import './polyfills/polyfills'
import {createApp} from 'vue'
import App from './App.vue'
import {router} from './router/router'
import store from './store/store'
import '@/assets/app.scss'
import globalFunction from './utils/globalFunction'
import {setLocale} from 'yup'
import jQuery from 'jquery'
import {yupLocaleRu} from '@/configs/yupLocale'
import '@/configs/yupValidationMethods.js'
import {fullscreenImagePlugin} from '@/utils/test/fullscreenImagePlugin'
import * as Sentry from '@sentry/vue'
import Stats from 'stats.js/src/Stats'
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import '@coddicat/vue-pinch-scroll-zoom/style.css'
import './registerServiceWorker'

if (import.meta.env.VUE_APP_FPS_COUNTER || localStorage.getItem('VUE_APP_FPS_COUNTER')) {
  const stats = new Stats()
  stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
  stats.dom.style.pointerEvents = 'none'
  stats.dom.style.opacity = '0.7'
  document.body.appendChild(stats.dom)

  function animate() {
    stats.begin()
    // monitored code goes here
    stats.end()
    requestAnimationFrame(animate)
  }

  requestAnimationFrame(animate)
}

const $ = jQuery
window.$ = $
window.require = (str) => {
  return str.replace('@', '')
}
setLocale(yupLocaleRu)

console.log(import.meta.env.VUE_APP_VERSION)

store.dispatch('user/restore')
store.dispatch('app/init')

const app = createApp(App)

//VUE_APP_SENTRY_PROPAGATION_TARGET

if (import.meta.env.VUE_APP_SENTRY_DSN) {
  const tracePropagationTargets = ['localhost']
  if (import.meta.env.VUE_APP_SENTRY_PROPAGATION_TARGET) {
    tracePropagationTargets.push(import.meta.env.VUE_APP_SENTRY_PROPAGATION_TARGET)
  }
  Sentry.init({
    app,
    release: `v${import.meta.env.VUE_APP_VERSION}`,
    dsn: import.meta.env.VUE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const oneSignalAppId = import.meta.env.VUE_APP_ONE_SIGNAL_APP_ID
if (oneSignalAppId) {
  app.use(OneSignalVuePlugin, {
    appId: oneSignalAppId,
    autoResubscribe: true,
    autoPrompt: true,
    notifyButton: {
      enable: true
    },
    // notifyButton: {
    //   enable: true
    // },
    // serviceWorkerPath: undefined,
    // serviceWorkerParam: {},
    allowLocalhostAsSecureOrigin: true,
  })
}

app
  .use($)
  .use(VueVirtualScroller)
  .use(fullscreenImagePlugin)
  .use(globalFunction)
  .use(store)
  .use(router)
  .mount('#app')

if (!import.meta.env.VUE_APP_IS_DEVELOPMENT) {
  app.config.errorHandler = async (err, vm, info) => {
    console.log('VueErrorHandler')
    console.error('Error:', err)
    console.error('Vue component:', vm)
    console.error('Additional info:', info)

    await store.dispatch('app/setCrashedState', true)
    throw err
  }
}

export {app}
