<template>
  <div :class="{wrapper: !borderLess}">
    <div class="data">
      <Skeleton v-if="isLoading" width="128" height="14" />
      <span v-else>{{ text }}</span>

      <Skeleton v-if="isLoading" width="36" height="14" />
      <span v-else>{{ Number(completed * 100).toFixed(0) }}%</span>
    </div>
    <div class="line">
      <span
          v-if="!isLoading"
          :style="{width: cssProgress}"
      />
    </div>
  </div>
</template>

<script setup>
import {computed} from 'vue'
import Skeleton from '@/components/UI/Skeleton.vue'

const props = defineProps({
  text: {
    type: String,
  },
  completed: {
    type: Number,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  borderLess: {
    type: Boolean,
    default: false,
  },
})

const cssProgress = computed(() => {
  return `${Number(props.completed * 100).toFixed(0)}%`
})

</script>

<style lang="scss" scoped>

.wrapper {
  border-radius: 12px;
  background: #2F2F2F;
  padding: 16px;
}

.data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  span {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;

    &:nth-child(2) {
      font-weight: 500;
    }
  }
}

.line {
  height: 6px;
  border-radius: 6px;
  background: #4b4b4b;
  position: relative;

  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 6px;
    border-radius: 6px;
    background: #cf2e2e;
  }
}
</style>
