<template>
  <head-new
      page_title="Личные данные"
      :back-action="() => router.push({name: $route.meta.returnName ?? 'EditProfile'})"
  />

  <div class="main_container form">

    <FormTextInput
        name="first_name"
        label="Имя"
    />

    <FormTextInput
        name="last_name"
        label="Фамилия"
    />

    <FormTextInput
        name="email"
        label="Электронная почта"
    />

    <FormDate
        name="birth_date"
        label="Дата рождения"
        inputmode="tel"
    />

    <FormSelect
        name="city"
        label="Город"
        :options="state.cityList"
    />

    <appButton
        full-width
        :loading="isSubmitting"
        :disabled="isSubmitting"
        @click="onSubmit"
    >
      Сохранить
    </appButton>

  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue";
import FormTextInput from '@/components/form/FormTextInput.vue'
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import store from '@/store/store'
import {onMounted, reactive} from 'vue'
import {router} from '@/router/router'
import AppButton from '@/components/UI/AppButton.vue'
import FormDate from '@/components/form/FormDate.vue'
import moment from 'moment'
import FormSelect from '@/components/form/FormSelect.vue'
import {useRoute} from "vue-router";

const route = useRoute()

const state = reactive({
  file: false,
  newAvatar: '',
  cityList: [],
})

const {handleSubmit, setErrors, isSubmitting, setFieldValue, setValues} = useForm({
  validationSchema: Yup.object({
    first_name: Yup.string().label('Имя').required(),
    last_name: Yup.string().label('Фамилия').required(),
    email: Yup.string().label('Электронная почта').required().email(),
    city: Yup.string().label('Город').required(),
    birth_date: Yup.string().label('Дата рождения').dateValidate('Некорректная дата'),
  }),
});

onMounted(() => {
  setValues({
    first_name: store.state.user.profile.first_name,
    last_name: store.state.user.profile.last_name,
    birth_date: (moment(store.state.user.profile.birth_date, "YYYY-MM-DD")).format('DD.MM.YYYY'),
    city: store.state.user.profile.city ?? ' ',
    email: store.state.user.profile.email ?? ' ',
  })
  state.cityList = store.state.user.directories.city_list.map(city => {
    return {
      value: city,
      label: city,
    }
  })
})

const onSubmit = handleSubmit(async values => {
  const payload = {
    ...values,
  }
  // if (state.file) {
  //   payload.avatar = state.file
  // }
  if(payload.birth_date) {
    let day = moment(payload.birth_date, "DD.MM.YYYY")
    payload.birth_date = day.format('YYYY-MM-DD')
  }

  await store.dispatch('user/updateProfile', payload)
  await router.push({name: route.meta.isCheckList ? 'CheckList' : 'EditProfile'})
})


</script>

<style lang="scss" scoped>

.form {
  margin-top: 24px;
}

.form_control {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

</style>
