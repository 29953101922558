<template>
  <button @click="handleChange(!inputValue)" class="switch_btn" :class="{ active: inputValue }">
    <span></span>
  </button>
</template>

<script setup>
import {toRef} from 'vue'
import {useField} from 'vee-validate'

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
})


// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name')

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});
</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.switch_btn {
  width: 40px;
  height: 24px;
  background: $color-4;
  border-radius: 40px;
  padding: 2px;
  transition: 0.2s ease all;

  span {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: block;
    background: $color-1;
    transition: 0.2s ease all;
    margin-left: 0;
  }
}

.switch_btn.active {
  background: $color-5 !important;

  span {
    margin-left: 16px;
  }
}

.dark-theme .switch_btn {
  background: $color-13;
}
</style>
