<template>
  <div v-if="!state.isLoading"
       class="wheels"
       @click="() => {
         if(!state.roulette.detail) {
          state.showModal = true
         }
       }"
  >
    <div class="wheelsBackground"/>
    <div
        class="wheelsBackgroundActive"
        :class="{active: !state.roulette.course && !state.roulette.detail }"
    />

    <img src="@/assets/imgs/rouletteWheel.png" alt=""/>

    <div class="content">
      <div>
        <Typography class="title" variant="subtitle1">
          Колесо Фортуны
        </Typography>
        <Typography v-if="state.roulette.course" class="subtitle" variant="text1" color="#fff">
          {{ state.roulette.course.title }}
        </Typography>
        <Typography v-else-if="!state.roulette.detail" class="subtitle" variant="text1" color="#fff">
          Крути и выигрывай новый курс каждый день
        </Typography>
        <Typography
            v-else
            variant="text1"
            color="#fff"
            class="row4"
        >
          {{ state.roulette.detail }}
        </Typography>
      </div>

      <Typography v-if="state.roulette.promo_ends_at" class="nextPlay" variant="text1" color="#fff">
        <Icon class="icon" width="16" height="16" :icon="require(`@/assets/icons/white_16_clock.svg`)"/>
        {{ timeDiff(state.timeNow, state.roulette.promo_ends_at) }}
      </Typography>
      <Typography v-else-if="!state.roulette.detail" class="alert" variant="text1" color="#fff">
        Крутить
      </Typography>
    </div>
  </div>
  <Skeleton
      v-else
      full-width
      height="112"
      radius="20"
  />

  <RouletteModal
      v-if="state.roulette"
      :isShown="state.showModal"
      @close="state.showModal = false"
      @won="(roulette) => state.roulette = roulette"
      :closeOnClickAway="false"
      :roulette="state.roulette"
  />
</template>

<script setup>
import {onBeforeMount, onMounted, onUnmounted, reactive, watch} from 'vue'
import RouletteModal from './RouletteModal.vue'
import Typography from '@/components/UI/Typography.vue'
import Skeleton from '@/components/UI/Skeleton.vue'
import {timeDiff} from '@/utils/timeLeft'
import Icon from '@/components/UI/Icon.vue'
import {appAxios} from '@/axios'
// {{props.roulette.course}}
// {{props.roulette.promo_ends_at}}

const props = defineProps({})

const state = reactive({
  roulette: null,
  isLoading: true,
  showModal: false,
  userCourse: props.roulette?.course,

  interval: null,
  timeNow: Date.now(),
})

onBeforeMount(async () => {
  state.isLoading = true
  try {
    const resRoulette = await appAxios.roulette.check()
    state.roulette = resRoulette.data
    state.isLoading = false
  } catch (err) {
    if (err?.response?.data?.detail) {
      state.roulette = {
        detail: err.response.data.detail,
      }
      state.isLoading = false
      return
    }
    throw err
  }
})

watch(() => state.timeNow, async t => {
  if(!state.roulette?.promo_ends_at) {
    return
  }
  if((new Date(state.roulette.promo_ends_at)) - t < 0) {
    state.showModal = false
    state.roulette = {}

    // state.isLoading = true
    try {
      const resRoulette = await appAxios.roulette.check()
      state.roulette = resRoulette.data
      state.isLoading = false
    } catch (err) {
      if (err?.response?.data?.detail) {
        state.roulette = {
          detail: err.response.data.detail,
        }
        // state.isLoading = false
        return
      }
      throw err
    }
  }
})

// timeDiff(state.timeNow, state.roulette.promo_ends_at)

onMounted(() => {
  state.interval = setInterval(() => {
    state.timeNow = Date.now()
  }, 1e3)
})

onUnmounted(() => {
  clearInterval(state.interval)
})

</script>

<style lang="scss" scoped>

.title {
  margin-bottom: 8px;
}

.row4 {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alert {
  padding: 8px 12px;
  border-radius: 37px;
  background: rgba(255, 255, 255, 0.2);
}

.nextPlay {
  min-width: 96px;
  display: flex;
  gap: 4px;
  justify-content: left;
  align-items: center;
  padding: 4px 12px;
  border-radius: 37px;
  background: #CF2E2E;
}

.wheels {
  border-radius: 20px;
  background: #222222;
  position: relative;
  overflow: hidden;
  min-height: 112px;
  max-height: 112px;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  .wheelsBackground {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    //background: #222222;
    //background: rgb(247, 183, 51, .6);
    background: radial-gradient(circle at 100%, rgb(247, 183, 51, .35), #222222 40%, #222222 75%, #222222 75%);
    //background: radial-gradient()(90deg, #222222 0%, #222222 60%, #f7b733 100%);
  }

  .wheelsBackgroundActive {
    transition: all .5s;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #fc5e1a 0%, #f7b733 100%);
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }

  img {
    position: absolute;
    z-index: 3;
    right: 0;
    user-select: none;
  }

  .content {
    padding: 12px;
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 70%;
    overflow: hidden;
    flex-grow: 1;
  }
}
</style>
