<template>
  <img
      :src="imagesLinkCache.cache(props.data.image)"
      v-fullscreen-image="{
        imageUrl: [imagesLinkCache.cache(props.data.image)],
        zoom: true,
      }"
  />
</template>

<script setup>
import {imagesLinkCache} from '@/utils/imagesLinkCache'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

</script>

<style lang="scss" scoped>

img {
  width: 100%;
  border-radius: 12px;
  user-select: none;
}

</style>
