

export const productsStatus = {
    moderation: {
        value: 'moderation',
        title: 'Отправлен на модерацию',
        color: '#FF9900',
    },
    rejected: {
        value: 'rejected',
        title: 'Отклонен модерацией',
        color: '#FF303A',
    },
    active: {
        value: 'active',
        title: 'Опубликован на ярмарке',
        color: '#20B537',
    },
    draft: {
        value: 'draft',
        title: 'Сохранен как черновик',
        color: '#A6A5A5',
    }
}
