<template>
  <div style="margin-bottom: 140px">
    <OnboardingEditAchievement
        :achievementId="state.achievementId"
        @close="state.achievementId = null"
    />

    <Typography variant="h2" style="margin-bottom: 8px">
      Кейсы и достижения
    </Typography>

    <Typography variant="body2" color="#A6A5A5">
      Добавь топ-5 личных побед, которыми ты гордишься
    </Typography>

    <div v-if="store.state.user.profile.achievements.length" class="achievementList">
      <Card v-for="item in store.state.user.profile.achievements" class="achievement">
        <AppButton
            v-if="store.state.user.profile.achievements.length > 1"
            variant="transparent"
            class="removeBtn"
            @click="state.isDeleteId = item.id"
        >
          <Icon :icon="require(`@/assets/icons/gray_16_trash.svg`)"/>
        </AppButton>

        <Typography variant="text1" class="achievementText">
          {{ item.title }}
        </Typography>

        <appButton
            full-width
            variant="secondary"
            @click="editAchievement(item.id)"
        >
          Редактировать
        </appButton>

      </Card>
    </div>

    <AppButton
        v-if="store.state.user.profile.achievements.length < achievementsLimit"
        full-width
        variant="secondary"
        size="large"
        @click="createAchievement"
        style="margin-top: 24px"
    >
      Добавить кейс или достижение
    </AppButton>

    <FormError
        v-show="errors.error"
        style="margin-top: 12px; margin-bottom: 0"
        name="error"
    />

    <div style="flex-grow: 1 "/>

    <div
        style="padding: 0 16px; background: #171717; border-top: 1px solid #2B2B2B; position: fixed; bottom: 0; left: 0; right: 0;"
    >
      <Typography
          style="margin: 24px 0 24px 0"
          variant="v14-600"
          center
      >
        Возникли сложности?
        <AppLink
            color="#FF303A"
            @click="openExternalURL(store.state.user.directories.support_chat_link.link)"
        >
          Напишите боту
        </AppLink>
      </Typography>

      <AppButton
          style="margin-top: 8px; margin-bottom: 24px"
          size="large"
          full-width
          @click="onSubmit"
          :loading="isSubmitting"
          :disabled="isSubmitting"
      >
        Продолжить
      </AppButton>
    </div>

    <BottomDrawer
        :is-shown="state.isDeleteId"
        title="Удаление данных"
        @close="state.isDeleteId = null"
    >
      <Typography variant="body1" color="#D3D2D2">
        Ты точно хочешь удалить кейс или достижение?
      </Typography>

      <div class="modalButtons">

        <appButton
            variant="secondary"
            @click="state.isDeleteId = null"
        >
          Оставить
        </appButton>

        <appButton
            variant="redSecondary"
            @click="deleteAchievement(state.isDeleteId)"
            color="#FF303A"
            :disabled="state.isDeleting"
            :loading="state.isDeleting"
        >
          Удалить
        </appButton>

      </div>
    </BottomDrawer>
  </div>
</template>

<script setup>
import Typography from "@/components/UI/Typography.vue"
import AppButton from "@/components/UI/AppButton.vue"
import {useForm} from "vee-validate"
import * as Yup from "yup"
import store from "@/store/store.js"
import {reactive, watch} from "vue"
import Card from "@/components/UI/Card.vue"
import Icon from "@/components/UI/Icon.vue"
import OnboardingEditAchievement from "@/views/profile/onboarding/OnboardingEditAchievement.vue"
import BottomDrawer from "@/components/BottomDrawer.vue"
import {appAxios} from "@/axios.js"
import FormError from "@/components/form/FormError.vue"
import AppLink from "@/components/AppLink.vue";
import {openExternalURL} from "@/utils/openExternalURL.js";

const {handleSubmit, values, errors, isSubmitting, setFieldValue, setValues, setErrors} = useForm({
  validationSchema: Yup.object({
    error: Yup.mixed()
        .test('required', 'Необходимо добавить кейс или достижение', () => store.state.user.profile.achievements.length)
  })
})

const achievementsLimit = store.state.user.directories.user_achievements_limit

watch(() => store.state.user.profile.achievements, () => {
  setErrors({error: ''})
})

const props = defineProps({
  nextStep: {
    type: Function,
    require: true,
  },
  createUserStepIfNotExist: {
    type: Function,
    require: true,
  },
})

const state = reactive({
  achievementId: null,

  isDeleteId: null,
  isDeleting: false,
})

function createAchievement() {
  state.achievementId = 'new'
}

function editAchievement(id) {
  state.achievementId = id
}

async function deleteAchievement(id) {
  state.isDeleting = true

  try {
    const found = store.state.user.profile.achievements.find(item => item.id.toString() === id.toString())
    const res = await appAxios.achievements.remove(found)
    const resResult = await appAxios.achievements.fetchAll()
    await store.commit('user/setProfile', {
      achievements: resResult.data,
    })
    state.isDeleteId = null
  } catch (e) {
    console.log('e', e)
  }
  state.isDeleting = false
}


const onSubmit = handleSubmit(async values => {
  await props.nextStep()
})

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.wrapper {
  min-height: 100dvh;
  max-height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.middleText {
  flex-direction: column;
  text-align: center;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.info {
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
  gap: 8px;

  & > .icon {
    flex-shrink: 0;
  }
}

.achievementList {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
}

.achievement {
  position: relative;
}

.achievementText {
  margin-bottom: 16px;
  margin-top: 4px;
  padding-right: 20px;
}

.removeBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.modalButtons {
  display: flex;
  gap: 8px;
  margin-top: 32px;
  margin-bottom: 16px;

  & > * {
    flex-grow: 1;
    flex-basis: 100px;
  }
}
</style>
