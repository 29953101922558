import {BaseIndexedSlice} from "@/indexedDB/BaseIndexedSlice.js";

export const indexedStore = {
  currentUserID: localStorage.getItem('indexedStoreUserID'),

  slices: {
    base: new BaseIndexedSlice('base'),
    myCourses: new BaseIndexedSlice('courses'),
    courses: new BaseIndexedSlice('courses'),
  },

  async clear() {

  },

  async init(id) {
    const slices = this.slices
    const userID = id.toString()
    localStorage.setItem('indexedStoreUserID', userID)

    if (this.currentUserID === userID) {
      await Promise.all(Object.keys(slices).map(key => {
        return slices[key].init()
      }))
      return
    }

    await Promise.all(Object.keys(slices).map(key => {
      return slices[key].clear()
    }))
  },
}

window.indexedStore = indexedStore
