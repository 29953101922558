<template>
  <div style="margin-bottom: 140px">
    <Typography variant="h2" style="margin-bottom: 8px">
      Аватарка
    </Typography>

    <Typography variant="body2" style="margin-bottom: 24px" color="#A6A5A5">
      Выбери лучшую фотку, чтобы запомниться!
    </Typography>

    <div
        style="position: relative"
    >
      <FormImageAvatar
          name="avatar"
          :image-preview="state.imagePreview"
      />
      <Typography
          style="position: absolute; top: 52px; left: 118px"
          variant="subtitle"
      >
        {{state.profile.first_name}} {{state.profile.last_name}}
      </Typography>
    </div>

    <div style="flex-grow: 1 "/>

    <div
        style="padding: 0 16px; background: #171717; border-top: 1px solid #2B2B2B; position: fixed; bottom: 0; left: 0; right: 0;"
    >
      <Typography
          style="margin: 24px 0 24px 0"
          variant="v14-600"
          center
      >
        Возникли сложности?
        <AppLink
            color="#FF303A"
            @click="openExternalURL(store.state.user.directories.support_chat_link.link)"
        >
          Напишите боту
        </AppLink>
      </Typography>

      <AppButton
          style="margin-top: 8px; margin-bottom: 24px"
          size="large"
          full-width
          @click="onSubmit"
          :loading="isSubmitting"
          :disabled="isSubmitting"
      >
        Продолжить
      </AppButton>
    </div>
  </div>
</template>

<script setup>
import Typography from "@/components/UI/Typography.vue"
import AppButton from "@/components/UI/AppButton.vue"
import {onBeforeMount, onMounted, reactive} from "vue"
import store from "@/store/store.js"
import {useForm} from "vee-validate"
import * as Yup from "yup"
import FormImageAvatar from "@/components/form/FormImageAvatar.vue"
import {openExternalURL} from "@/utils/openExternalURL.js";
import AppLink from "@/components/AppLink.vue";

const state = reactive({
  imagePreview: null,
  profile: null,
})

const {handleSubmit, setErrors, isSubmitting, setFieldValue, setValues, setFieldError} = useForm({
  validationSchema: Yup.object({
    avatar: Yup.mixed()
        .test('required', 'Загрузите фотографию', (value) => state.imagePreview || value)
        // .test('Size', 'File size must be less than 20kb', (value) => value && value?.size <= 500)
  }),
})

const props = defineProps({
  nextStep: {
    type: Function,
    require: true,
  },
  createUserStepIfNotExist: {
    type: Function,
    require: true,
  },
})

onBeforeMount( () => {
  const {profile} = store.state.user
  state.profile = profile

  if(store.state.user.profile.avatar) {
    state.imagePreview = store.state.user.profile.avatar
  }
})

const onSubmit = handleSubmit(async values => {
  if(values.avatar) {
    await store.dispatch('user/updateProfile', {avatar: values.avatar})
  }
  await props.nextStep()
})

</script>

<style lang="scss" scoped>

.takePhone.takePhone {
  display: flex;
  margin-top: 16px;
  margin-bottom: 12px;
}

.edit_img {
  display: block;
  cursor: pointer;

  .profileImage {
    width: 106px;
    height: 106px;
    background-image: url("@/assets/images/profile/takePhoto.svg");

    .empty {
      background-image: url("@/assets/images/profile/takePhotoEmpty.svg");
    }
  }

  #user_img {
    display: none;
  }
}

.userAvatar {
  position: absolute;
  z-index: -2;
  width: 106px;
  height: 106px;
  border-radius: 16px;
  background-size: cover;
  background-repeat: no-repeat;
}

.userAvatarUpdate {
  position: absolute;
  z-index: -1;
  width: 106px;
  height: 106px;
  border-radius: 16px;
  background-size: cover;
  background-repeat: no-repeat;
}

</style>
