<template>
  <div class="news_one">
    <head-new to="News" />

    <div class="main_container news_item_content">
      <img src="@/assets/images/news_1.png" alt="" class="main_img_1" />
      <div class="date">
        <span>5 декабря, 15:32</span>
        <div class="icon">
          <img src="@/assets/images/news_icon_1.svg" alt="" />
        </div>
      </div>
      <div class="title">Кейс: как провести мероприятие на 150 000 человек</div>
      <p class="description">
        Организация мероприятий — это сложное и ответственное дело, требующее тщательной подготовки и организации всего
        процесса. Однако, когда количество участников достигает 150 000 человек, задача становится ещё более сложной.
        В данной статье мы рассмотрим кейс успешной организации и проведения мероприятия такого масштаба и поделимся
        ключевыми этапами его реализации.
        <br /><br />
        1. Планирование и концепция: Первым шагом является определение целей и задач мероприятия. Это могут быть
        различные музыкальные, спортивные или культурные фестивали, где главной целью является развлечение
        и удовлетворение потребностей посетителей. Важно определить тематику мероприятия, его формат, даты и место
        проведения, а также бюджет.
        <br /><br />
        2. Поиск и выбор площадки: Одно из главных условий успешного проведения мероприятия — это правильный выбор
        площадки. Необходимо учесть количество посетителей, наличие необходимой инфраструктуры, доступность места
        и другие факторы. Также важно получить все необходимые разрешения и лицензии для проведения мероприятия.
      </p>
      <img src="@/assets/images/news_2.png" alt="" class="main_img_2" />
      <div class="subtitle">Наш командный мозговой штурм у доски</div>
      <p class="description">
        3. Привлечение спонсоров и партнёров: <br />
        Организация мероприятия такого масштаба требует значительных затрат. Поэтому важно привлечь спонсоров
        и партнёров, которые помогут в финансировании и организации различных активностей на площадке. Работа
        с потенциальными партнёрами и рассылка предложений — Таким образом, организация мероприятия на 150 000 человек
        требует не только хорошей организации и планирования, но и наличия всех необходимых ресурсов и команды
        профессионалов, способных справиться с таким масштабом. Следуя ключевым этапам и использованию опыта успешных
        кейсов, можно добиться успешной организации и проведения подобного мероприятия.
      </p>
      <div class="link">Источник: <a href="#">телеграм-канал Татьяны Маричевой</a></div>
      <div class="comment">
        <button>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.056 5H18.944C19.527 5 20 5.473 20 6.056V13.444C20 14.027 19.527 14.5 18.944 14.5H17.056C16.473 14.5 16 14.027 16 13.444V6.056C16 5.473 16.473 5 17.056 5V5Z"
              stroke="#A6A5A5"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 13.4279L12.351 18.1789C11.672 19.0639 10.346 19.0859 9.637 18.2249V18.2249C9.372 17.9039 9.228 17.4999 9.228 17.0839V13.8129H6.132C5.531 13.8129 4.97 13.5129 4.636 13.0139L4.307 12.5229C4.012 12.0819 3.926 11.5329 4.073 11.0239L5.432 6.30194C5.654 5.53094 6.359 4.99994 7.161 4.99994L13.45 4.99994C13.95 4.99994 14.428 5.20794 14.768 5.57394L16 6.89994"
              stroke="#A6A5A5"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>0</span>
        </button>
        <button>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M21 12C21 7.038 16.962 3 12 3C7.037 3 3 7.037 3 12"
              stroke="#A6A5A5"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3 12C3 16.963 7.037 21 12 21C16.963 21 21 16.962 21 12"
              stroke="#A6A5A5"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.328 9.52002C15.19 9.52002 15.078 9.63202 15.079 9.77002C15.079 9.90802 15.191 10.02 15.329 10.02C15.467 10.02 15.579 9.90802 15.579 9.77002C15.579 9.63202 15.467 9.52002 15.328 9.52002"
              stroke="#A6A5A5"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path d="M9 15H15" stroke="#A6A5A5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M8.63899 9.5C8.50099 9.5 8.38899 9.612 8.38999 9.75C8.38999 9.888 8.50199 10 8.63999 10C8.77799 10 8.88999 9.888 8.88999 9.75C8.88999 9.612 8.77799 9.5 8.63899 9.5"
              stroke="#A6A5A5"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>4</span>
        </button>
        <button>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.944 19H5.056C4.473 19 4 18.527 4 17.944V10.556C4 9.973 4.473 9.5 5.056 9.5H6.944C7.527 9.5 8 9.973 8 10.556V17.944C8 18.527 7.527 19 6.944 19V19Z"
              stroke="#A6A5A5"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 10.5721L11.649 5.82106C12.328 4.93606 13.654 4.91406 14.363 5.77506V5.77506C14.628 6.09606 14.772 6.50006 14.772 6.91606V10.1871H17.868C18.469 10.1871 19.03 10.4871 19.364 10.9861L19.693 11.4771C19.988 11.9181 20.074 12.4671 19.927 12.9761L18.568 17.6981C18.346 18.4691 17.641 19.0001 16.839 19.0001H10.55C10.05 19.0001 9.572 18.7921 9.232 18.4261L8 17.1001"
              stroke="#A6A5A5"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>16</span>
        </button>
      </div>
      <div class="line"></div>
      <div class="main_title">Читайте далее</div>
      <div class="news_one_slider">
        <swiper :slidesPerView="'auto'" :spaceBetween="12" :modules="modules">
          <swiper-slide v-for="(item, i) in list" :key="i">
            <router-link :to="{ name: 'NewsId', params: { id: i + 1 } }" class="news_item">
              <img :src="require(`@/assets/images/news_item_${item.img}.png`)" alt="" class="main" />
              <div class="icon">
                <img :src="require(`@/assets/images/news_icon_${item.icon}.svg`)" alt="" />
              </div>
              <span>{{ item.date }}</span>
              <div class="item_title">{{ item.title }}</div>
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper/modules";
import HeadNew from "@/components/Head.vue";

export default {
  components: {
    HeadNew,
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
  data() {
    return {
      list: [
        { img: 1, icon: 1, date: "5 декабря, 15:32", title: "Кейс: как провести мероприятие на 150 000 человек" },
        { img: 2, icon: 2, date: "5 декабря, 15:32", title: "О последних событиях в инфобизнесе" },
        { img: 3, icon: 3, date: "4 декабря", title: "О последних событиях в инфобизнесе" },
        {
          img: 4,
          icon: 4,
          date: "3 декабря",
          title: "Заголовок информационной новости от платформы в две строки с транкейтом",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.news_one {
  .news_item_content {
    margin-top: 16px;
  }

  .main_img_1 {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 16px;
  }

  .date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    span {
      color: #a6a5a5;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }

    .icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.12);
    }
  }

  .title {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
  }

  .description {
    color: #fff;
    font-family: "Manrope", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
  }

  .main_img_2 {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 12px;
  }

  .subtitle {
    color: #a6a5a5;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 24px;
  }

  .link {
    color: #a6a5a5;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;

    a {
      display: inline;
      color: #2178f3;
      font-weight: 500;
      text-decoration-line: underline;
    }
  }

  .comment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 32px;

    button {
      width: calc(100% / 3 - 16px / 3);
      border-radius: 12px;
      background: #222;
      padding: 12px 0 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;

      span {
        color: #a6a5a5;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }

  .line {
    background: #4d4c4c;
    height: 1px;
    width: 100%;
    margin-bottom: 32px;
  }

  .news_one_slider {
    margin-top: 12px;

    .swiper {
      overflow: visible;
    }

    .swiper-slide {
      width: 290px;
    }

    .news_item {
      border-radius: 16px;
      background: #222;
      padding: 12px 12px 16px 12px;
      position: relative;

      img.main {
        border-radius: 6px;
        width: 100%;
        display: block;
        margin-bottom: 12px;
      }

      .icon {
        position: absolute;
        top: 16px;
        right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.66);
      }

      span {
        color: #a6a5a5;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 4px;
      }

      .item_title {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
