<template>
  <div
      class="textInput"
      :class="{ 'has-error': !!errorMessage, success: meta.valid, fullWidth }"
  >
    <div class="inputWrapper">
      <button
          @click="handleChange(!inputValue)"
      >
        <span class="check" :class="{ active: inputValue }"/>
        <Typography
            variant="body1"
            color="#fff"
            left
            style="margin: 3px 0; width: 100%"
        >
          {{ label }}
        </Typography>
      </button>
    </div>
    <div v-if="textInfo && !onlyInput" class="textInfo">
      {{ textInfo }}
    </div>
    <div v-if="!onlyInput" class="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup>
import {toRef} from 'vue'
import {useField} from 'vee-validate'
import Typography from '@/components/UI/Typography.vue'

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  textInfo: {
    type: String,
  },
  onlyInput: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
})


// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name')

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});
</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.check {
  width: 24px;
  height: 24px;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    width: 14px;
    height: 14px;
    margin: 3px;
    border-radius: 24px;
    border: 2px solid #D3D2D2;
  }

  &.active {
    &:before {
      background: #CF2E2E;
      border: 2px solid #CF2E2E;
    }

    &:after {
      content: " ";
      position: absolute;
      top: 0;
      display: block;
      width: 24px;
      height: 24px;
      background: url("@/assets/icons/white_24_checkBox.svg") no-repeat;
      background-size: contain;
    }
  }
}

.inputWrapper {
  min-height: 24px;

  button {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 6px;
    position: relative;
  }
}

label {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
}

.errorMessage {
  color: rgb(255, 48, 58);
  font-size: 12px;
  font-weight: 500;
  min-height: 14px;
  line-height: 12px;
  margin-bottom: 4px;
}

.textInfo {
  color: #838181;
  font-size: 12px;
  font-weight: 500;
  min-height: 14px;
  line-height: 12px;
  margin-bottom: 4px;
}

</style>
