<template>
  <PWABlock
      v-if="!state.isStandalone"
  />
  <NewVersionAvailable
      v-else-if="store.state.app.isNewVersionAvailable"
  />
  <router-view
      :key="$route.fullPath"
      v-else-if="!store.state.user.isRestoring && store.state.app.isOnline && !store.state.app.isCrashed"
  />
  <NoConnection
      v-else-if="!store.state.app.isOnline"
  />
  <SomethingGoingWrong
      v-else-if="store.state.app.isCrashed"
  />
  <transition
      v-show="store.state.user.isRestoring"
      name="fade"
  >
    <SplashScreen v-if="state.splashScreenNeeded"/>
  </transition>
</template>

<script setup>
import {onBeforeMount, onMounted, onUnmounted, reactive, watch} from 'vue'
import store from './store/store'
import SplashScreen from '@/views/SplashScreen.vue'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import NoConnection from '@/views/system/NoConnection.vue'
import SomethingGoingWrong from '@/views/system/SomethingGoingWrong.vue'
import {pwaUtils} from "@/utils/pwaUtils.js"
import PWABlock from "@/views/system/PWABlock.vue"
import NewVersionAvailable from '@/views/system/NewVersionAvailable.vue';

const state = reactive({
  splashScreenNeeded: true,
  isStandalone: pwaUtils.isStandalone(),
  checkInterval: null,
})

watch(() => store.state.user.isRestoring, isRestoring => {
  if (!isRestoring) {
    setTimeout(() => {
      state.splashScreenNeeded = false
    }, 500)
  } else {
    state.splashScreenNeeded = true
  }
})

onBeforeMount(async () => {
  imagesLinkCache.restore()
  $('body').addClass('dark-theme')

  state.checkInterval = setInterval(() => {
    state.isStandalone = pwaUtils.isStandalone()
  }, 10e3)
})

onUnmounted(() => {
  clearInterval(state.checkInterval)
})

</script>

<style lang="scss">
@import "@/assets/variables";

.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s ease, background-color .3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  background-color: transparent;
}
</style>
