<template>
  <head-new
      page_title="Ищу"
      :back-action="() => router.push({name: $route.meta.returnName ?? 'Profile'})"
  />
  <div class="main_container form">

    <Card class="info">
      <Icon class="icon" width="20" height="20" :icon="require(`@/assets/icons/user_20_search.svg`)"/>
      <Typography variant="body1">
        Кого ты ищешь?
      </Typography>
    </Card>

    <Card>
      <FormOptionSelect
          class="select"
          name="lookingFor1"
          placeholder="Например, маркетолога"
          :options="state.filteredLookingForList1"
          :canAcceptAnyValue="true"
      />

      <FormOptionSelect
          class="select"
          name="lookingFor2"
          placeholder="Например, директора по рекламе"
          :options="state.filteredLookingForList2"
          :canAcceptAnyValue="true"
      />

      <FormOptionSelect
          class="select"
          name="lookingFor3"
          placeholder="Например, руководителя отдела продаж"
          :options="state.filteredLookingForList3"
          :canAcceptAnyValue="true"
      />
    </Card>

    <appButton
        class="submitButton"
        full-width
        :loading="isSubmitting"
        :disabled="isSubmitting"
        @click="onSubmit"
    >
      Сохранить
    </appButton>

  </div>

</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import store from '@/store/store'
import {onBeforeMount, onMounted, reactive, watch} from 'vue'
import {router} from '@/router/router'
import AppButton from '@/components/UI/AppButton.vue'
import Card from '@/components/UI/Card.vue'
import Typography from '@/components/UI/Typography.vue'
import Icon from '@/components/UI/Icon.vue'
import FormOptionSelect from '@/components/form/FormOptionSelect.vue'
import {appAxios} from '@/axios'
import {useRoute} from "vue-router";

const route = useRoute()

const state = reactive({
  isLoading: true,
  skillList: [],
  filteredLookingForList1: [],
  filteredLookingForList2: [],
  filteredLookingForList3: [],
})

const {handleSubmit, values, isSubmitting, setValues} = useForm({
  validationSchema: Yup.object({
    lookingFor1: Yup.string().label('Ниша'),
    lookingFor2: Yup.string().label('Ниша'),
    lookingFor3: Yup.string().label('Ниша'),
  }),
});

watch(values, (values) => {
  const used = {
    [values.lookingFor1]: true,
    [values.lookingFor2]: true,
    [values.lookingFor3]: true,
  }

  state.filteredLookingForList1 = state.skillList.filter(skill => {
    if (values.lookingFor1 === skill.value) {
      return true
    }
    return !used[skill.value]
  })

  state.filteredLookingForList2 = state.skillList.filter(skill => {
    if (values.lookingFor2 === skill.value) {
      return true
    }
    return !used[skill.value]
  })

  state.filteredLookingForList3 = state.skillList.filter(skill => {
    if (values.lookingFor3 === skill.value) {
      return true
    }
    return !used[skill.value]
  })
})

onBeforeMount(async () => {
  state.skillList = [
    ...store.state.user.directories.skill_list.map(skill => {
      return {
        value: skill.id,
        label: skill.title,
      }
    }),
    ...store.state.user.profile.looking_for.map(userSkill => {
      return {
        value: userSkill.skill.id,
        label: userSkill.skill.title,
      }
    })
  ]
  state.isLoading = false
})

onMounted(() => {
  const [p1, p2, p3] = store.state.user.profile.looking_for
  setValues({
    lookingFor1: p1 ? p1.skill.id : '',
    lookingFor2: p2 ? p2.skill.id : '',
    lookingFor3: p3 ? p3.skill.id : '',
  })
})

const onSubmit = handleSubmit(async values => {
  const selectedLookingFor = []
  if (values.lookingFor1) {
    selectedLookingFor.push(values.lookingFor1)
  }
  if (values.lookingFor2) {
    selectedLookingFor.push(values.lookingFor2)
  }
  if (values.lookingFor3) {
    selectedLookingFor.push(values.lookingFor3)
  }

  const res = await appAxios.skills.fetchAll()
  const userSkills = res.data

  const toAdd = []
  const toRemove = []

  selectedLookingFor.filter(lookingForID => {
    const found = userSkills.find(userSkill => userSkill.skill.id === lookingForID)
    if (found) {
      return
    }
    if (lookingForID.toString().includes('|isNew|')) {
      toAdd.push({
        title: lookingForID.replace('|isNew|', '')
      })
    } else {
      const found = store.state.user.directories.skill_list.find(p => p.id === lookingForID)
      if (found) {
        toAdd.push({
          title: found.title,
        })
      }
    }
  })

  userSkills.forEach(userSkill => {
    const found = selectedLookingFor.find(skillId => userSkill.skill.id === skillId)
    if (found) {
      return
    }
    toRemove.push(userSkill)
  })

  const promises = [
    ...toRemove.map(item => appAxios.skills.remove(item.id)),
    ...toAdd.map(item => appAxios.skills.create(item.title)),
  ]

  await Promise.all(promises)

  const [skillsRes] = await Promise.all([
    appAxios.skills.fetchAll(),
  ])

  await store.commit('user/setProfile', {
    looking_for: skillsRes.data,
  })

  await router.push({name: route.meta.isCheckList ? 'CheckList' : 'Profile'})
})

</script>

<style lang="scss" scoped>
.info {
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
  gap: 8px;

  & > .icon {
    flex-shrink: 0;
  }
}

.submitButton {
  margin-top: 32px;
}

.select {
  margin-bottom: -24px;
}
</style>
