import * as Yup from 'yup'
import moment from 'moment'

Yup.addMethod(Yup.string, "dateValidate", function (errorMessage) {
  return this.test(`dateValidate`, errorMessage, function (value) {

    if(!value || value === '') {
      return true
    }

    const {path, createError} = this
    const date = moment(value, "DD.MM.YYYY")

    return (value.length === 10 && date.isValid() || createError({path, message: errorMessage}))
  })
})

Yup.addMethod(Yup.string, 'email', function (message) {
  return this.matches(/^[A-Za-z0-9а-яА-Я\._-]+@([A-Za-z0-9а-яА-Я]{1,2}|[A-Za-z0-9а-яА-Я]((?!(\.\.))[A-Za-z0-9а-яА-Я.-])+[A-Za-z0-9а-яА-Я])\.[A-Za-zа-яА-Я]{2,}$/, {
    name: 'email',
    message: 'Некорректный email',
    excludeEmptyString: true,
  })
})
