// "message"
// "image"
// "looking_for"
// "product"

import store from '@/store/store'
import {generateSimpleUID} from '@/utils/uid'

export function removeSavedMessage(uid) {
  try {
    const restored = JSON.parse(localStorage.getItem('chatWriteMessage'))
    if (restored.uid === uid) {
      return localStorage.removeItem('chatWriteMessage')
    }
  } catch (e) {

  }
}

export function getSavedMessage({uid, sent_by_id, user_to_id}) {
  try {
    const timeStamp = Date.now()
    const restored = JSON.parse(localStorage.getItem('chatWriteMessage'))

    if (restored.uid !== uid) {
      return null
    }

    if (timeStamp - restored.timeStamp > 60e3) {
      return null
    }

    if (restored.sent_by_id !== sent_by_id || restored.user_to_id !== user_to_id) {
      return null
    }

    return {
      message_type: restored.message_type,
      message: restored.message,
    }
  } catch (e) {
    return null
  }
}

export const createSendMessageByType = {
  product: ({user, product}) => {
    const uid = generateSimpleUID()

    const json = {
      uid,
      timeStamp: Date.now(),
      sent_by_id: store.state.user.profile.id,
      user_to_id: user.id,

      message_type: 'product',
      message: product.title,
    }

    localStorage.setItem('chatWriteMessage', JSON.stringify(json))

    return uid
  },
  lookingFor: ({user, skill}) => {
    const uid = generateSimpleUID()

    const json = {
      uid,
      timeStamp: Date.now(),
      sent_by_id: store.state.user.profile.id,
      user_to_id: user.id,

      message_type: 'looking_for',
      message: skill.title,
    }

    localStorage.setItem('chatWriteMessage', JSON.stringify(json))

    return uid
  },
}
