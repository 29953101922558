import {printValue} from 'yup';

const mixed = {
  default: '${path} является недействительным',
  required: '${path} обязательное поле',
  defined: '${path} должен быть определен',
  notNull: '${path} не может быть нулевым',
  oneOf: '${path} должен быть одним из следующих значений: ${values}',
  notOneOf: '${path} не должен быть одним из следующих значений: ${values}',
  notType: ({path, type, value, originalValue}) => {
    const isCast = originalValue != null && originalValue !== value;
    let msg =
      `${path} должен быть \`${type}\` тип, ` +
      `Но окончательное значение было: \`${printValue(value, true)}\`` +
      (isCast
        ? ` (бросить из значения \`${printValue(originalValue, true)}\`).`
        : '.');

    if (value === null) {
      msg +=
        `\n Если «нуль» предназначено как пустое значение, обязательно отметить схему как` +
        ' `.nullable()`';
    }

    return msg;
  },
};

const string = {
  length: '${path} должен быть точно ${length} символа',
  min: '${path} должен быть как минимум ${min} символа',
  max: '${path} должен быть не более ${max}',
  matches: '${path} должен соответствовать следующему: "${regex}"',
  email: '${path} обязательное поле',
  url: '${path} должен быть действительный URL',
  uuid: '${path} должен быть действительным UUID',
  trim: '${path} Должен быть обрезанной строкой',
  lowercase: '${path} Должен быть строчной строкой',
  uppercase: '${path} должна быть строка верхнего чехла',
};

const number = {
  min: '${path} должен быть больше или равен ${min}',
  max: '${path} должно быть меньше или равна ${max}',
  lessThan: '${path} должно быть меньше, чем ${less}',
  moreThan: '${path} должен быть больше, чем ${more}',
  positive: '${path} должно быть положительным числом',
  negative: '${path} должно быть отрицательным числом',
  integer: '${path} должно быть целым числом',
};

const date = {
  min: '${path} Поле должно быть позже ${min}',
  max: '${path} Поле должно быть в более раннем, чем ${max}',
};

const boolean = {
  isValue: '${path} Поле должно быть ${value}',
};

const object = {
  noUnknown: 'Поле ${path} не может иметь ключи, не указанные в форме объекта',
};

const array = {
  min: '${path} Поле должно иметь по крайней мере ${min} элементы',
  max: '${path} Поле должно иметь меньше или равное ${max} элементам',
  length: '${path} должны иметь ${length} элементы',
};

export const yupLocaleRu ={
  mixed,
  string,
  number,
  date,
  boolean,
  object,
  array,
}
