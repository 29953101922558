<template>
  <component
      :is="{...state.layout}"
  >
    <slot/>
  </component>
</template>

<script setup>
import {useRoute} from 'vue-router'
import HomeLayout from '@/layouts/HomeLayout.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue'
import {onBeforeMount, reactive} from 'vue'

const route = useRoute()

const layouts = {
  HomeLayout,
  EmptyLayout,
}

const state = reactive({
  layout: null,
})

onBeforeMount(() => {
  state.layout = layouts[route.meta.layout]
  if (!state.layout) {
    state.layout = EmptyLayout
  }
})

</script>

<style lang="scss" scoped>

</style>
