<template>
  <Card class="info" :class="{[variant]: variant}">
    <div class="header">
      <Icon class="icon" width="20" height="20" :icon="require(`@/assets/icons/user_20_suitcase.svg`)"/>

      <Typography variant="subtitle1">
        Кейсы и достижения
      </Typography>

      <AppButton
          v-if="isEdit"
          class="btn"
          size="small"
          variant="transparent"
          @click="() => router.push({name: 'EditAchievements'})"
      >
        <Icon
            :icon="achievements.length ? require(`@/assets/icons/gray_20_pen.svg`) : require(`@/assets/icons/red_20_add.svg`)"
        />
      </AppButton>
    </div>

    <div v-if="achievements.length" class="tags">
      <template v-for="(item, index) in achievements">
        <div v-if="index < 3 || state.isShowAll" class="tag">
          <Typography variant="body2" pre-line color="#D3D2D2">
            {{ item.title }}
          </Typography>
        </div>
      </template>
    </div>

    <div class="showMoreBtn" v-if="!state.isShowAll && achievements.length > 3">
      <AppButton
          full-width
          size="small"
          variant="transparent"
          @click="state.isShowAll = true"
      >
        Показать еще
        <Icon :icon="require(`@/assets/icons/white_24_arrowDown.svg`)"/>
      </AppButton>
    </div>
  </Card>
</template>

<script setup>
import Card from '@/components/UI/Card.vue'
import Icon from '@/components/UI/Icon.vue'
import Typography from '@/components/UI/Typography.vue'
import {reactive} from 'vue'
import AppButton from '@/components/UI/AppButton.vue'
import {router} from '@/router/router'

const props = defineProps({
  variant: {
    type: String,
    required: false,
  },
  achievements: {
    type: Array,
    default: [],
  },
  isEdit: {
    type: Boolean,
    default: false,
  }
})

const state = reactive({
  isShowAll: false,
})
</script>

<style lang="scss" scoped>

.info {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.light {
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.08);
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  position: relative;

  .btn {
    position: absolute;
    top: -9px;
    right: -9px;
  }

  & > .icon {
    flex-shrink: 0;
  }
}

.tags {
  display: flex;
  flex-direction: column;
  width: 100%;

  .tag {
    padding: 12px 0;
    border-bottom: 1px solid #303030;
  }

  & > .tag:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.info.light {
  .tags {
    .tag {
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    }

    & > .tag:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.showMoreBtn {
  width: 100%;
  margin-top: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  padding-top: 4px;
  margin-bottom: -8px;
}

</style>
