<template>
  <div v-if="isShown" class="wrapper">
    <head-new
        page_title="Фильтры"
        :back-action="back"
    />

    <div class="main_container" style="margin-top: 24px;">

      <FormError
          v-show="errors.error"
          name="error"
          style="margin-bottom: 12px"
      />

      <FormTextInput
          name="searchQuery"
          placeholder=""
          label="Ключевые слова"
          onlyInput
      />

      <Card style="margin-bottom: 8px">
        <Typography variant="subtitle1" style="margin-bottom: 8px">
          Профессия
        </Typography>

        <FormOptionSelect
            class="select"
            name="profession"
            placeholder="Профессия"
            :options="state.professionList"
            onlyInput
        />
      </Card>

      <Card style="margin-bottom: 8px">
        <Typography variant="subtitle1" style="margin-bottom: 8px">
          Ниши
        </Typography>
        <FormOptionSelect
            class="select"
            name="niche1"
            placeholder="Например, здоровье"
            :options="state.filteredNicheList1"
            onlyInput
        />
        <FormOptionSelect
            class="select"
            name="niche2"
            placeholder="Например, финансы"
            :options="state.filteredNicheList2"
            onlyInput
        />
        <FormOptionSelect
            class="select"
            name="niche3"
            placeholder="Например, инфобизнес"
            :options="state.filteredNicheList3"
            onlyInput
        />
      </Card>

      <Card style="margin-bottom: 8px">
        <Typography variant="subtitle1" style="margin-bottom: 8px">
          География
        </Typography>
        <FormOptionSelect
            class="select"
            name="city1"
            placeholder="Например, Москва"
            :options="state.filteredCityList1"
            onlyInput
        />
        <FormOptionSelect
            class="select"
            name="city2"
            placeholder="Например, Дубай"
            :options="state.filteredCityList2"
            onlyInput
        />
        <FormOptionSelect
            class="select"
            name="city3"
            placeholder="Например, Барселона"
            :options="state.filteredCityList3"
            onlyInput
        />
      </Card>
    </div>

    <div class="main_container">
      <div
          class="applyWrapper"
          style="background: #171717"
      >
        <AppButton
            class="applyBtn"
            full-width
            :disabled="isSubmitting"
            :loading="isSubmitting"
            @click="onSubmit"
            type="submit"
        >
          Искать
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import {nextTick, onBeforeMount, reactive, watch} from 'vue'
import HeadNew from '@/components/Head.vue'
import {useModalScrollingFix} from '@/hooks/useModalScrollingFix'
import Typography from '@/components/UI/Typography.vue'
import AppButton from '@/components/UI/AppButton.vue'
import store from '@/store/store'
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import FormTextInput from '@/components/form/FormTextInput.vue'
import Card from "@/components/UI/Card.vue";
import FormOptionSelect from "@/components/form/FormOptionSelect.vue"
import FormError from "@/components/form/FormError.vue";

const {handleSubmit, isSubmitting, setFieldValue, setValues, errors, values, setErrors} = useForm({
  validationSchema: Yup.object({
    searchQuery: Yup.string()
        .test('check1', 'Ключевые слова должен быть как минимум 3 символа', value => {
          if (value && value.length < 3) {
            return false
          }
          return true
        })
        .test('check1', 'Ключевые слова должны быть не более 50 символов', value => {
          if (value && value.length > 50) {
            return false
          }
          return true
        }),
    error: Yup.mixed()
        .test('test1', 'Заполни хотя бы одно поле', () =>
            values.city1
            || values.city2
            || values.city3
            || values.niche1
            || values.niche2
            || values.niche3
            || values.profession
            || values.searchQuery
        ),
  }),


});

const modalScrollingFix = useModalScrollingFix()

const filters = defineModel('filters')
const emit = defineEmits(['close', 'search', 'applyFilters'])

const props = defineProps({
  isShown: {
    type: Boolean,
    default: false,
  },
  filteredList: {
    type: Array,
  }
})

const state = reactive({
  selectedFilter: null,

  professionList: [],

  nicheList: [],
  filteredNicheList1: [],
  filteredNicheList2: [],
  filteredNicheList3: [],

  cityList: [],
  filteredCityList1: [],
  filteredCityList2: [],
  filteredCityList3: [],
})

watch(() => props.isShown, (value) => {
  if (value) {
    modalScrollingFix.open()
    requestAnimationFrame(() => {
      setErrors({error: ''})
      requestAnimationFrame(() => {
        setErrors({error: ''})
        requestAnimationFrame(() => {
          setErrors({error: ''})
        })
      })
    })
  } else {
    modalScrollingFix.close()
  }
})

function back() {
  emit('close')
}

const onSubmit = handleSubmit(async values => {
  emit('applyFilters', values)
})

onBeforeMount(() => {
  state.professionList = store.state.user.directories.profession_list.map(profession => {
    return {
      value: profession.id,
      label: profession.title,
    }
  })
  state.nicheList = [
    ...store.state.user.directories.niche_list.map(niche => {
      return {
        value: niche.id,
        label: niche.title,
      }
    }),
    ...store.state.user.profile.niches.map(userNiche => {
      return {
        value: userNiche.niche.id,
        label: userNiche.niche.title,
      }
    })
  ]
  state.cityList = store.state.user.directories.city_list.map(city => {
    return {
      value: city,
      label: city,
    }
  })
  nextTick(() => {
    setValues(filters.value)
    requestAnimationFrame(() => {
      setErrors({error: ''})
      requestAnimationFrame(() => {
        setErrors({error: ''})
        requestAnimationFrame(() => {
          setErrors({error: ''})
        })
      })
    })
  })
})

watch(values, (values) => {
  //niche
  {
    const used = {
      [values.niche1]: true,
      [values.niche2]: true,
      [values.niche3]: true,
    }
    state.filteredNicheList1 = state.nicheList.filter(niche => {
      if (values.niche1 === niche.value) {
        return true
      }
      return !used[niche.value]
    })
    state.filteredNicheList2 = state.nicheList.filter(niche => {
      if (values.niche2 === niche.value) {
        return true
      }
      return !used[niche.value]
    })
    state.filteredNicheList3 = state.nicheList.filter(niche => {
      if (values.niche3 === niche.value) {
        return true
      }
      return !used[niche.value]
    })
  }
  // city
  {
    const used = {
      [values.city1]: true,
      [values.city2]: true,
      [values.city3]: true,
    }
    state.filteredCityList1 = state.cityList.filter(city => {
      if (values.city1 === city.value) {
        return true
      }
      return !used[city.value]
    })
    state.filteredCityList2 = state.cityList.filter(city => {
      if (values.city2 === city.value) {
        return true
      }
      return !used[city.value]
    })
    state.filteredCityList3 = state.cityList.filter(city => {
      if (values.city3 === city.value) {
        return true
      }
      return !used[city.value]
    })
  }
})

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.wrapper {
  position: fixed;
  z-index: 10;
  background: #171717;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: calc(100vh - $bottomMenuOffset);
  min-height: calc(100dvh - $bottomMenuOffset);
  max-height: calc(100vh - $bottomMenuOffset);
  max-height: calc(100dvh - $bottomMenuOffset);
  overflow: auto;
  padding-bottom: 72px;
}

.item {
  border-bottom: 1px solid #303030;
  padding: 12px 0;

  &:last-child {
    border-bottom: none;
  }
}

.itemList {
  margin-bottom: 48px;
}

.applyBtn {

}

.applyWrapper {
  position: fixed;
  bottom: 80px;
  width: calc(100% - 32px);
  max-width: 448px;
  padding: 16px 0;
}

</style>
