const data = {
  community: 'Сообщества',
  institution: 'Образование',
  course: 'Курс',
}

export const educations = {
  'institution': 'institution',
  'course': 'course',
  'community': 'community',
}

export function getEducationName(type) {
  return data[type]
}

const pluralData = {
  community: 'communities',
  institution: 'institutions',
  course: 'courses',
}

export function educationTypeToPlural(key) {
  return pluralData[key]
}

export const educationTypeArray = [
  'institution',
  'course',
  'community',
]

export const educationFields = {
  institution: {
    title: true,
    institution_type: true,
    degree: true,
    ended_at: true,
    images: true,
  },
  course: {
    title: true,
    ended_at: true,
    images: true,
  },
  community: {
    title: true,
    images: true,
  },
}

export const educationFieldsMeta = {
  institution: {
    institution_type: {
      label: 'Образование',
      textInfo: '',
    },
    title: {
      label: 'Учебное заведение',
      textInfo: '',
    },
    degree: {
      label: 'Степень и направление',
      textInfo: '',
    },
    ended_at: {
      label: 'Год окончания',
      textInfo: 'Если ты ещё учишься, укажи год окончания',
    },
    images: {
      label: 'Фотографии диплома (до 3 штук)',
      textInfo: '',
    },
  },
  course: {
    title: {
      label: 'Название',
      textInfo: '',
    },
    ended_at: {
      label: 'Год окончания',
      textInfo: '',
    },
    images: {
      label: 'Фотографии (до 3 шт.)',
      textInfo: '',
    },
  },
  community: {
    title: {
      label: 'Название',
      textInfo: '',
    },
    images: {
      label: 'Фотографии (до 3 шт.)',
      textInfo: '',
    },
  },
}
