export function declination(v1, v2, v3, count) {
  let res = "";
  switch (count % 100) {
    case 11:
    case 12:
    case 13:
    case 14:
      return v1;
    default:
      switch (count % 10) {
        case 0:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          return v1;
        case 1:
          return v2;
        case 2:
        case 3:
        case 4:
          return v3;
      }
  }
  return "";
}
