import moment from 'moment'

const localStorageKey = 'imagesCacheLinks'

let state = {
  // 'pathname': {
  //   url: '',
  //   expires: 0,
  // }
}

export const imagesLinkCache = {

  store() {
    localStorage.setItem(localStorageKey, JSON.stringify(state))
  },

  restore() {
    const restored = JSON.parse(localStorage.getItem(localStorageKey))
    if (typeof restored !== 'object' || restored === null) {
      return
    }
    state = restored
  },

  cache(imgURL) {

    if (!imgURL) {
      return imgURL
    }

    try {
      const url = new URL(imgURL)
      const rawDate = url.searchParams.get('X-Amz-Date')
      const rawExpires = url.searchParams.get('X-Amz-Expires')

      if (!rawExpires || !rawDate) {
        return imgURL
      }

      if (state[url.pathname]) {
        const now = Date.now()
        const cache = state[url.pathname]
        if (now < cache.expires) {
          return cache.url
        }
      }

      const day = moment(rawDate, 'YYYYMMDD-hhmmss')
      day.add(Number(rawExpires) / 2, 'seconds')
      const time = new Date(day.format())

      const expires = time.getTime()

      state[url.pathname] = {
        url: imgURL,
        expires,
      }
      imagesLinkCache.store()

      return imgURL
    } catch (e) {
      return imgURL
    }
  },

}
