<template>
  <div
      v-if="profile.contact_phone || profile.contact_telegram || profile.contact_instagram || profile.contact_vk || profile.contact_youtube || profile.contact_tiktok"
      class="contacts"
  >
    <AppButton
        v-if="profile.contact_phone"
        variant="secondary"
        @click="clickPhone"
    >
      <Icon :icon="require(`@/assets/icons/contacts/phone.svg`)"/>
    </AppButton>

    <AppButton
        v-if="profile.contact_telegram"
        variant="secondary"
        @click="clickOpenURL(profile.contact_telegram)"
    >
      <Icon :icon="require(`@/assets/icons/contacts/telegram.svg`)"/>
    </AppButton>

    <AppButton
        v-if="profile.contact_instagram"
        variant="secondary"
        @click="clickOpenURL(profile.contact_instagram)"
    >
      <Icon :icon="require(`@/assets/icons/contacts/instagram.svg`)"/>
    </AppButton>

    <AppButton
        v-if="profile.contact_vk"
        variant="secondary"
        @click="clickOpenURL(profile.contact_vk)"
    >
      <Icon :icon="require(`@/assets/icons/contacts/vk.svg`)"/>
    </AppButton>

    <AppButton
        v-if="profile.contact_youtube"
        variant="secondary"
        @click="clickOpenURL(profile.contact_youtube)"
    >
      <Icon :icon="require(`@/assets/icons/contacts/youtube.svg`)"/>
    </AppButton>

    <AppButton
        v-if="profile.contact_tiktok"
        variant="secondary"
        @click="clickOpenURL(profile.contact_tiktok)"
    >
      <Icon :icon="require(`@/assets/icons/contacts/tiktok.svg`)"/>
    </AppButton>
  </div>
</template>

<script setup>
import Icon from '@/components/UI/Icon.vue'
import AppButton from '@/components/UI/AppButton.vue'

const props = defineProps({
  variant: {
    type: String,
    required: false,
  },
  profile: {
    type: Object,
    default: {},
  },
})

function clickPhone() {
  const phone = props.profile.contact_phone.replace(/[+ ()-]/g, '')
  window.location.href = 'tel:' + phone;
}

function clickTelegram() {
  // const contact = props.profile.contact_telegram
  // try {
  //   window.location.href = 'tg://' + contact
  // } catch (e) {
  //   console.log(e, e)
  //   // console.log('wtf', 'https://' + contact)
  //   // window.location.href = 'https://' + contact;
  // }
  // This would be a very, very hacky way to do this... but would this work?
  //
  // Put the link in as normal...
  // But attach an onclick handler to it, that sets a timer and adds an onblur handler for the window
  // (in theory) if the browser handles the link (application X) will load stealing the focus from the window...
  // If the onblur event fires, clear the timer...
  // Otherwise in 3-5seconds let your timeout fire... and notify the user "Hmm, looks like you don't have the Mega Uber Cool Application installed... would you like to install it now? (Ok) (Cancel)"
}

function clickOpenURL(url) {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: 'https://' + url,
  }).click();
}

</script>

<style lang="scss" scoped>

.contacts {
  display: flex;
  gap: 8px;

  & > * {
    flex-grow: 1;
  }
}

</style>
