<template>
  <div class="head_new">
    <div class="main_container">
      <div class="buttonSpace">
        <div
            v-if="backAction"
            @click="backAction()"
            class="link"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M2 12H22" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 5L2 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 19L2 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>

      <div v-if="isLoading">
        <Skeleton
            width="220"
            height="16"
        />
      </div>
      <div v-else-if="!isLoading && (typeof title === 'string' || typeof page_title === 'string')" class="title">
        {{ page_title ? page_title : title }}
      </div>
      <slot v-else/>

      <div class="buttonSpace" v-if="!noSpace || (rightAction && rightIcon)">
        <template v-if="rightAction && rightIcon">
          <button
              v-if="!rightIsButton"
              class="rightIcon"
              :style="{backgroundImage: urlToCssURL(rightIcon)}"
              @click="rightAction"
          />
          <AppButton
              v-else
              class="appButton"
              variant="secondary"
              size="large"
              @click="rightAction"
          >
            <Icon :icon="rightIcon"/>
          </AppButton>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from '@/components/UI/Skeleton.vue'
import {urlToCssURL} from '@/utils/urlToCssURL'
import AppButton from '@/components/UI/AppButton.vue'
import Icon from '@/components/UI/Icon.vue'

export default {
  methods: {urlToCssURL},
  components: {Icon, AppButton, Skeleton},
  props: {
    to: {
      type: String,
      default: "#",
    },
    page_title: {
      type: String,
    },
    title: {
      type: String,
    },
    backAction: {
      type: Function,
      default: null,
    },
    backgroundColor: {
      type: String,
      default: 'transparent'
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    rightIcon: {
      type: String,
      default: '',
    },
    rightAction: {
      type: Function,
      default: null,
    },
    rightIsButton: {
      type: Boolean,
      default: false,
    },
    noSpace: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.head_new {
  .main_container {
    padding: $topOffset 16px 0 16px;
    height: 44px + $topOffset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: v-bind(backgroundColor);
  }

  .buttonSpace {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  .rightIcon {
    width: 24px;
    height: 24px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .link {
    cursor: pointer;
  }

  .title {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }

  //.sign_out {
  //  width: 100%;
  //  text-align: end;
  //  display: none;
  //}
}

.appButton {
  margin: -10px 0 0 -24px;
}
</style>
