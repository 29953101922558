<template>
  <div class="eventTags">
    <div
        class="eventTag"
    >
      {{ typesTranslate[eventType] }}
    </div>
  </div>
</template>

<script setup>

const typesTranslate = {
  all: 'Онлайн + Офлайн',
  online: 'Онлайн',
  offline: 'Офлайн',
}

const types = {
  all: ['online', 'offline'],
  online: ['online'],
  offline: ['offline'],
}

const props = defineProps({
  eventType: null,
  require: true,
})

</script>

<style lang="scss" scoped>

.eventTags {
  display: flex;
  gap: 4px;
}

.eventTag {
  padding: 4px 12px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.12);
  font-size: 14px;
  font-weight: 500;
  user-select: none;

  &.online {
    background: rgba(43, 178, 255, 0.12);
    color: rgba(43, 178, 255, 1);
  }

  &.offline {
    background: rgba(32, 181, 55, 0.12);
    color: rgba(32, 181, 55, 1);
  }
}

</style>
