<template>
  <head-new
      backgroundColor="#222222"
      :back-action="back"
  />
  <div class="main_container header">

    <div class="imageWrapper">
      <Skeleton
          v-if="state.isLoading"
          width="168"
          height="168"
      />
      <div
          v-else
          class="image"
          :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(state.course.preview_image))}"
      />
    </div>

    <div :style="{display: 'flex', gap: '16px', flexDirection: 'column'}">

      <div>
        <div class="moduleNumber">
          <div
              v-if="state.isLoading"
          >
            <Skeleton
                variant="text"
                width="120"
                height="14"
            />
          </div>
          <span v-else>Модуль {{ state.course.modules.indexOf(state.module) + 1 }}</span>
        </div>

        <div class="moduleTitle">
          <div
              v-if="state.isLoading"
          >
            <Skeleton
                variant="text"
                width="240"
                height="18"
            />
          </div>
          <span v-else>{{ state.module.title }}</span>
        </div>
      </div>

      <Skeleton
          v-if="state.isLoading"
          full-width
          height="48"
          radius="12"
      />
      <MenuItem
          v-else
          text="Возникли вопросы?"
          :disabled="state.course.course_questions.length === 0 && store.state.user.directories.course_question_list === 0"
          :icon="require(`@/assets/icons/gray_24_question.svg`)"
          :action="() => router.push({
            name: 'StudyModuleQuestions',
            params: {
              userCourseId: props.userCourseId,
              moduleId: props.moduleId,
            },
          })"
      />

      <CourseProgressbar
          :is-loading="state.isLoading"
          :text="state.studentModule && getCourseProgressText(state.studentModule.progress)"
          :completed="state.studentModule && getCourseProgressPercent(state.studentModule.progress)"
      />
    </div>
  </div>
  <div class="main_container">

    <div class="lessonList" v-if="!state.isLoading">
      <CourseLessonLink
          v-for="(item, i) in state.lessons"
          :index="i"
          :lesson="item"
          @click="() => {
            router.push({name: 'StudyModuleLesson', params: {
              userCourseId: state.studentCourse.id,
              moduleId: props.moduleId,
              lessonId: item.id
            }})
          }"
      />
    </div>

    <div class="lessonList" v-if="!state.isLoading">
      <CourseLessonLink
          v-for="(item, i) in state.materials"
          :index="i"
          :lesson="item"
          @click="() => {
            router.push({name: 'StudyModuleLesson', params: {
              userCourseId: state.studentCourse.id,
              moduleId: props.moduleId,
              lessonId: item.id
            }})
          }"
      />
    </div>

  </div>

</template>

<script setup>
import HeadNew from '@/components/Head.vue'
import {onBeforeMount, reactive} from 'vue'
import {appAxios} from '@/axios'
import CourseProgressbar from '@/components/UI/CourseProgressbar.vue'
import Skeleton from '@/components/UI/Skeleton.vue'
import MenuItem from '@/components/UI/MenuItem.vue'
import {urlToCssURL} from '@/utils/urlToCssURL'
import CourseModuleLink from '@/components/Courses/CourseModuleLink.vue'
import CourseLessonLink from '@/components/Courses/CourseLessonLink.vue'
import {router} from '@/router/router'
import {getCourseProgressPercent, getCourseProgressText} from '@/utils/courseUtils'
import {lessonTypes} from '@/configs/lessonTypes'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import store from '@/store/store'

const props = defineProps({
  userCourseId: {
    type: String,
  },
  moduleId: {
    type: String,
  },
})

const state = reactive({
  isLoading: true,
  studentCourse: null,
  studentModule: null,
  course: null,
  module: null,

  studentLessonsMap: {},
  lessons: [],
  materials: [],
})

onBeforeMount(async () => {
  try {
    const res = await appAxios.myCourses.fetchOne(props.userCourseId)
    const module = res.data.course.modules.find(module => module.id === Number(props.moduleId))

    state.studentCourse = res.data
    state.course = res.data.course
    state.module = module

    let studentModule = state.studentCourse.student_modules.find(st => st.module.id === module.id)
    if (!studentModule) {
      const resStudentModule = await appAxios.myModules.start(module.id)
      studentModule = resStudentModule.data
    }
    state.studentModule = studentModule

    state.studentModule.student_lessons.forEach(sl => {
      state.studentLessonsMap[sl.lesson.id] = sl
    })

    let lastUnfinishedLessonIndex = null
    state.lessons = state.module.lessons
        .filter(lesson => lesson.lesson_type === lessonTypes.lesson)
        .map((lesson, index) => {
          const isFinished = !!state.studentLessonsMap[lesson.id]?.finished_at
          if (lastUnfinishedLessonIndex === null && !isFinished) {
            lastUnfinishedLessonIndex = index
          }
          return {
            ...lesson,
            isFinished: isFinished,
            isLastUnfinished: lastUnfinishedLessonIndex === index,
          }
        })
    state.materials = state.module.lessons
        .filter(lesson => lesson.lesson_type === lessonTypes.material)
        .map((lesson) => {
          const isFinished = !!state.studentLessonsMap[lesson.id]?.finished_at
          return {
            ...lesson,
            isFinished: isFinished,
            isLastUnfinished: false,
          }
        })

  } catch (e) {
    // todo: error?
  } finally {
    state.isLoading = false
  }
})

function back() {
  router.push({
    name: 'StudyCourse',
    params: {
      userCourseId: props.userCourseId,
    },
  })
}
</script>

<style lang="scss" scoped>

.header {
  background: #222222;
  border-radius: 0 0 20px 20px;
  padding-bottom: 16px;
  margin-bottom: 24px;
}

.imageWrapper {
  width: 168px;
  height: 168px;
  margin: 0 auto 12px auto;
}

.image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.moduleNumber {
  font-size: 14px;
  font-weight: 500;
  color: #A6A5A5;
  text-align: center;
  margin-bottom: 8px;
}

.moduleTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
}

.moduleList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

//.lessonList:not(:last-child) {
//  border-bottom: 1px solid #2B2B2B;
//}

</style>
