import axios from "axios"
import store from '@/store/store.js'

async function getVersion() {
  const res = await axios({
    url: `/version.json?&timestamp=${new Date().getTime()}`, //your url
    method: 'GET',
    responseType: 'json',
    headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
  })
  return res.data.version
}

async function checkVersion() {
  const requiredVersion = await getVersion()
  const currentVersion = import.meta.env.VUE_APP_VERSION

  if (requiredVersion !== currentVersion) {
    await store.dispatch('app/setIsNewVersionAvailable', true)
  }
}

checkVersion()
setInterval(() => {
  checkVersion()
}, 5 * 60e3)

// todo: remove it

// const updateSW = registerSW({
//   immediate: true,
//   onNeedRefresh() {
//     console.log('onNeedRefresh')
//   },
//   onOfflineReady() {
//     console.log('onOfflineReady')
//   },
// })
//
// document.addEventListener('swUpdated', () => {
//   console.log('up?')
// }, {once: true});
//
// // Refresh all open app tabs when a new service worker is installed.
// if (navigator.serviceWorker) {
//   navigator.serviceWorker.addEventListener('controllerchange', () => {
//     console.log('wtf')
//     // if (this.refreshing) return;
//     // this.refreshing = true;
//     // window.location.reload();
//   });
// }

// function forceSWupdate() {
//     if ('serviceWorker' in navigator) {
//         navigator.serviceWorker.getRegistrations().then(function (registrations) {
//             for (let registration of registrations) {
//                 registration.update()
//             }
//         })
//     }
// }
// forceSWupdate()

