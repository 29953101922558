import OnboardingStep1 from "@/views/profile/onboarding/OnboardingStep1.vue"
import OnboardingStep2 from "@/views/profile/onboarding/OnboardingStep2.vue"
import OnboardingStep3 from "@/views/profile/onboarding/OnboardingStep3.vue"
import OnboardingStep4 from "@/views/profile/onboarding/OnboardingStep4.vue"
import OnboardingStep5 from "@/views/profile/onboarding/OnboardingStep5.vue"
import OnboardingStep6 from "@/views/profile/onboarding/OnboardingStep6.vue"
import OnboardingStep7 from "@/views/profile/onboarding/OnboardingStep7.vue"
import OnboardingStep8 from "@/views/profile/onboarding/OnboardingStep8.vue"

export const OnboardingSteps = {
  1: OnboardingStep1,
  2: OnboardingStep2,
  3: OnboardingStep3,
  4: OnboardingStep4,
  5: OnboardingStep5,
  6: OnboardingStep6,
  7: OnboardingStep7,
  8: OnboardingStep8,
}
