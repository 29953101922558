<template>
  <div style="margin-bottom: 140px">
    <Typography variant="h2" style="margin-bottom: 8px">
      О себе
    </Typography>

    <Typography variant="body2" style="margin-bottom: 24px" color="#A6A5A5">
      Главные факты, о которых стоит знать другим
    </Typography>

    <FormTextArea
        class="textArea"
        label="О себе"
        name="about_me"
        :text-info="`${values.about_me?.length}/350 символов`"
        :rows="6"
    />

    <Typography
        variant="v14-400"
        color="#D3D2D2"
        style="margin-bottom: 8px; margin-top: 4px"
    >
      Что пишут о себе другие:
    </Typography>

    <Card>
      <div style="display: flex; gap: 8px ;align-items: center">
        <Avatar
            size="tiny"
            radius="8"
            :image="require(`@/assets/images/profile/avatar.png`)"
        />
        <Typography
            variant="v18-700"
        >
          Ксения Пазюк
        </Typography>
      </div>

      <Typography
          variant="v14-500"
          color="#D3D2D2"
          style="padding-top: 12px;"
      >
        Инфопродюсер, продюсер продающих вебинаров. Запуски от🍋 Продажа основного продукта на автоворонках 33+🍋 Кейс: школа Джона Кехо | Коуч ICF
      </Typography>
    </Card>

    <div style="flex-grow: 1 "/>

    <div
        style="padding: 0 16px; background: #171717; border-top: 1px solid #2B2B2B; position: fixed; bottom: 0; left: 0; right: 0;"
    >
      <Typography
          style="margin: 24px 0 24px 0"
          variant="v14-600"
          center
      >
        Возникли сложности?
        <AppLink
            color="#FF303A"
            @click="openExternalURL(store.state.user.directories.support_chat_link.link)"
        >
          Напишите боту
        </AppLink>
      </Typography>

      <AppButton
          style="margin-top: 8px; margin-bottom: 24px"
          size="large"
          full-width
          @click="onSubmit"
          :loading="isSubmitting"
          :disabled="isSubmitting"
      >
        Продолжить
      </AppButton>
    </div>
  </div>
</template>

<script setup>
import Typography from "@/components/UI/Typography.vue"
import AppButton from "@/components/UI/AppButton.vue"
import {useForm} from "vee-validate"
import * as Yup from "yup"
import store from "@/store/store.js"
import {onBeforeMount, onMounted, reactive, watch} from "vue";
import Card from "@/components/UI/Card.vue";
import FormOptionSelect from "@/components/form/FormOptionSelect.vue";
import {appAxios} from "@/axios.js";
import {router} from "@/router/router.js";
import FormTextArea from "@/components/form/FormTextArea.vue";
import Avatar from "@/components/profile/Avatar.vue";
import Icon from "@/components/UI/Icon.vue";
import {openExternalURL} from "@/utils/openExternalURL.js";
import AppLink from "@/components/AppLink.vue";

const props = defineProps({
  nextStep: {
    type: Function,
    require: true,
  },
  createUserStepIfNotExist: {
    type: Function,
    require: true,
  },
})

const state = reactive({

})

const {handleSubmit, setErrors, values, isSubmitting, setValues} = useForm({
  validationSchema: Yup.object({
    about_me: Yup.string().label('О себе').required().max(350),
  }),
});

onMounted(() => {
  setValues({
    about_me: store.state.user.profile.about_me ?? '',
  })
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      setErrors({about_me: null})
    })
  })
})

const onSubmit = handleSubmit(async values => {
  const payload = {
    ...values,
  }
  await store.dispatch('user/updateProfile', payload)
  await props.nextStep()
})

</script>

<style lang="scss" scoped>

</style>
