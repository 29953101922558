<template>
  <div class="wheel_spin_wrapper">
    <div class="wheel-wrapper">
      <div class="wheel-pointer">
        <img src="../../../assets/images/spin_loop_wrapper.png" alt=""/>
      </div>
      <div class="wheel-bg" :class="{ freeze: freeze }" :style="`transform: rotate(${wheelDeg}deg)`">
        <img src="../../../assets/images/spin_loop.png" alt="" class="wheel-bg-img"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WheelSpin",
  props: {
    isSpin: Boolean,
  },
  emits: ["stopped"],
  data() {
    return {
      freeze: false,
      wheelDeg: 0,
      spinningInterval: null,
    }
  },
  computed: {},
  methods: {
  },
  watch: {
    isSpin(value) {
      console.log('value', value)
      if (value) {
        this.freeze = true;
        this.wheelDeg = 0;
        setTimeout(() => {
          this.freeze = false
          this.wheelDeg = 360 * 30
        }, 16)
        this.spinningInterval = setInterval(() => {
          // this.wheelDeg += 360 * 15
        }, 1e3)
      } else {
        setTimeout(() => {
          console.log('stoped')
          clearInterval(this.spinningInterval)
          this.freeze = true
          this.$emit('stopped')
        }, 1e3)
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/variables";

.wheel-wrapper {
  width: 450px;
  height: 450px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 58%);
  padding: 35px;
}

.wheel-pointer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-1px, -5px);
  }

  .rotate-btn {
    display: none;
  }
}

.wheel-bg {
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  overflow: hidden;
  transition: transform 30s linear;
  position: relative;

  .wheel-bg-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &.freeze {
    transition: none;
  }
}

.prize-list {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
}

.prize-item-wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 150px;
}

.prize-item {
  width: 100%;
  height: 100%;
  transform-origin: bottom;

  .prize-name {
    padding: 16px 0;
  }
}

@media only screen and (max-width: 992px) {
  .wheel-wrapper {
    width: 370px;
    height: 370px;
    padding: 28px;
  }
}
</style>
