<template>
  <div
      class="productInfo"
      @click="state.isSelectedProduct = product"
      style="cursor: pointer"
  >

    <div v-if="product.image" class="imgWrapper">
      <div
          class="img"
          :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(product.preview_image))}"
      />
    </div>

    <div>
      <div class="title">
        {{ product.title }}
      </div>
      <Typography variant="body1" style="white-space: pre-wrap;">
        {{ product.description }}
      </Typography>
    </div>

    <div class="bottom">
      <Avatar
          size="nano"
          :image="product.created_by?.preview_avatar"
          @click="openUser(product.created_by)"
      />

      <div
          style="flex-shrink: 1"
          @click="openUser(product.created_by)"
      >
        <Typography base="span" variant="body1" color="#D3D2D2">
          {{ product.created_by.first_name }}
        </Typography>
        {{ ' ' }}
        <Typography base="span" variant="body1" color="#D3D2D2">
          {{ product.created_by.last_name }}
        </Typography>
      </div>
      <div style="flex-grow: 1"/>

      <AppButton
          size="small"
          @click="state.isSelectedProduct = product"
      >
        Получить
      </AppButton>
    </div>
  </div>
</template>

<script setup>

import {urlToCssURL} from '@/utils/urlToCssURL.js'
import {imagesLinkCache} from '@/utils/imagesLinkCache.js'
import Typography from '@/components/UI/Typography.vue'
import AppButton from '@/components/UI/AppButton.vue'
import Avatar from '@/components/profile/Avatar.vue'
import {reactive} from 'vue'

const state = reactive({
  educationId: null,
  isDeleteId: null,
  isDeleting: false,
})

</script>

<style lang="scss" scoped>

</style>
