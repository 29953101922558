<template>
  <div class="userLocationAndAge">
    <Icon v-if="city" width="16" height="16" :icon="require(`@/assets/icons/gray_16_location.svg`)"/>
    <Typography variant="body1">
      {{ city }}
      <!--      {{ age ? ` ${city && age ? '•' : ''} ${age} ${declination("лет", "год", "года", age)}` : '' }}-->
    </Typography>
  </div>
</template>

<script setup>
import Icon from '@/components/UI/Icon.vue'
import Typography from '@/components/UI/Typography.vue'
import {computed} from 'vue'
import moment from 'moment'
import {declination} from '@/utils/declination'

const props = defineProps({
  city: {
    type: String,
  },
  birthDate: {
    type: String,
  }
})

const age = computed(() => {
  if (!props.birthDate) {
    return null
  }
  const m = moment(props.birthDate, "YYYY-MM-DD")
  return Math.abs(m.diff(Date.now(), 'years'))
})

</script>

<style lang="scss" scoped>
.userLocationAndAge {
  display: flex;
  align-items: center;
  gap: 4px;
}
</style>
