<template>
  <div class="wrapper">
    <head-new
        page_title="Кейсы и достижения"
        :back-action="() => router.push({name: $route.meta.returnName ?? 'Profile'})"
    />
    <div class="main_container">

      <div class="form">

        <Card class="info">
          <Icon class="icon" width="20" height="20" :icon="require(`@/assets/icons/user_20_suitcase.svg`)"/>

          <Typography variant="body1">
            Добавь топ-5 личных побед, которыми ты гордишься
          </Typography>
        </Card>

        <AppButton
            v-if="state.achievementList.length && achievementsLimit > state.achievementList.length"
            full-width
            variant="primary"
            size="large"
            @click="createAchievement"
            style="margin-bottom: 24px"
        >
          Добавить
        </AppButton>
      </div>

      <div v-if="state.achievementList.length" class="achievementList">

        <Card v-for="item in state.achievementList" class="achievement">
          <AppButton
              v-if="state.achievementList.length >= 2"
              variant="transparent"
              class="removeBtn"
              @click="state.isDeleteId = item.id"
          >
            <Icon :icon="require(`@/assets/icons/gray_16_trash.svg`)"/>
          </AppButton>

          <Typography variant="text1">
            Кейс или достижение
          </Typography>

          <Typography variant="body1" pre-line class="achievementText">
            {{ item.title }}
          </Typography>

          <appButton
              full-width
              variant="secondary"
              @click="editAchievement(item.id)"
          >
            Редактировать
          </appButton>

        </Card>

      </div>
      <div v-else class="middleText">

        <div>
          <Typography variant="subtitle">
            Здесь будут твои кейсы и достижения
          </Typography>

          <div style="height: 8px"/>

          <Typography variant="body1" color="#D3D2D2">
            Добавляй их и повышай доверие к себе
          </Typography>
        </div>

        <AppButton
            full-width
            variant="primary"
            size="large"
            @click="createAchievement"
            style="margin-top: 24px"
        >
          Добавить
        </AppButton>

      </div>

      <BottomDrawer
          :is-shown="state.isDeleteId"
          title="Удаление данных"
          @close="state.isDeleteId = null"
      >
        <Typography variant="body1" color="#D3D2D2">
          Ты точно хочешь удалить кейс или достижение?
        </Typography>

        <div class="modalButtons">

          <appButton
              variant="secondary"
              @click="state.isDeleteId = null"
          >
            Оставить
          </appButton>

          <appButton
              variant="redSecondary"
              @click="deleteAchievement(state.isDeleteId)"
          >
            Удалить
          </appButton>

        </div>

      </BottomDrawer>
    </div>
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import store from '@/store/store'
import {reactive} from 'vue'
import {router} from '@/router/router'
import AppButton from '@/components/UI/AppButton.vue'
import Card from '@/components/UI/Card.vue'
import Typography from '@/components/UI/Typography.vue'
import Icon from '@/components/UI/Icon.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import {appAxios} from '@/axios'
import {useRoute} from "vue-router"

const route = useRoute()

const achievementsLimit = store.state.user.directories.user_achievements_limit

const state = reactive({
  isLoading: true,
  achievementList: store.state.user.profile.achievements,
  isDeleteId: null,
})

function createAchievement() {
  router.push({
    name: route.meta.isCheckList ? 'CheckListCreateAchievement' : 'CreateAchievement',
  })
}

function editAchievement(id) {
  router.push({
    name: route.meta.isCheckList ? 'CheckListEditAchievement' : 'EditAchievement',
    params: {achievementId: id},
  })
}

async function deleteAchievement(id) {
  const found = store.state.user.profile.achievements.find(item => item.id.toString() === id.toString())
  const res = await appAxios.achievements.remove(found)

  const resResult = await appAxios.achievements.fetchAll()

  await store.commit('user/setProfile', {
    achievements: resResult.data,
  })

  state.achievementList = resResult.data

  state.isDeleteId = null
  await router.push({name: route.meta.isCheckList ? 'CheckListEditAchievements' : 'EditAchievements'})
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.wrapper {
  min-height: 100vh;
  min-height: 100dvh;
  max-height: 100vh;
  max-height: 100dvh;
  display: flex;
  flex-direction: column;
  padding-bottom: $homeLayoutPaddingBottom + 56px;
  margin-bottom: -$homeLayoutPaddingBottom;
  overflow: auto;
}

.middleText {
  flex-direction: column;
  text-align: center;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.info {
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
  gap: 8px;

  & > .icon {
    flex-shrink: 0;
  }
}

.achievementList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.achievement {
  position: relative;
}

.achievementText {
  margin-bottom: 16px;
  margin-top: 4px;
  padding-right: 20px;
}

.removeBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.modalButtons {
  display: flex;
  gap: 8px;
  margin-top: 32px;
  margin-bottom: 16px;

  & > * {
    flex-grow: 1;
  }
}
</style>
