<template>
  <Card
      class="card"
      :class="{isNotEnoughFilled: !profileProgress.isEnoughFilled}"
  >
    <Typography variant="subtitle1" style="margin-bottom: 4px;">
      <template v-if="!profileProgress.isEnoughFilled">
        Чек-лист успешного профиля
      </template>
      <template v-else>
        Для максимального результата
      </template>
    </Typography>

    <Typography variant="body1" color="#fff" style="margin-bottom: 12px;">
      <template v-if="!profileProgress.isEnoughFilled">
        Следуй подсказкам, заполняй профиль и получай доступ к расширенным функциям приложения
      </template>
      <template v-else>
        Заполни оставшиеся разделы
      </template>
    </Typography>

    <BarProgress
        :completed="!profileProgress.isEnoughFilled ? (profileProgress.enoughFilledCount) : (profileProgress.fullFilledCount)"
        :barCount="!profileProgress.isEnoughFilled ? profileProgress.enoughFields.length : profileProgress.fullFields.length"
        style="padding-bottom: 12px"
        color="#fff"
        :empty-color="!profileProgress.isEnoughFilled ? '#e58b85' : '#494949'"
    />

    <AppButton
        full-width
        :variant="!profileProgress.isEnoughFilled ? 'white' : undefined"
        :color="!profileProgress.isEnoughFilled ? '#1A1919' : undefined"
        size="small"
        @click="navigate({name: 'CheckList'})"
    >
      Заполнить
    </AppButton>

  </Card>
</template>

<script setup>
import Typography from '@/components/UI/Typography.vue'
import Card from '@/components/UI/Card.vue'
import AppButton from '@/components/UI/AppButton.vue'
import store from '@/store/store'
import {computed, reactive, watch} from 'vue'
import {router} from '@/router/router'
import BarProgress from "@/components/BarProgress.vue"

const profileProgress = computed(() => store.getters["user/profileProgress"])

const props = defineProps({
  progress: {
    type: String,
  }
})

function navigate(route) {
  router.push({
    ...route,
  })
}

</script>

<style lang="scss" scoped>

.card {
  margin-top: 16px;
  margin-bottom: 16px;

  &.isNotEnoughFilled {
    background: linear-gradient(105.04deg, #D53232 0%, #D6563A 100%);
  }
}


</style>
