import {onBeforeUnmount, onMounted, onUnmounted} from 'vue'

// todo: usedCount
let usedCount = 0

export function useModalScrollingFix() {

  function open() {
    document.body.classList.add('hideScroll')
  }

  function close() {
    document.body.classList.remove('hideScroll')
  }

  onBeforeUnmount(() => {
    close()
  })

  return {
    open,
    close,
  }
}
