<template>
  <div style="margin-bottom: 140px">
    <Typography variant="h2" style="margin-bottom: 8px">
      Ниши
    </Typography>

    <Typography variant="body2" style="margin-bottom: 24px" color="#A6A5A5">
      В каких нишах у тебя есть опыт работы?
    </Typography>

    <Card>
      <FormError
          v-show="errors.error"
          style="margin-bottom: 14px"
          name="error"
      />

      <FormOptionSelect
          class="select"
          name="niche1"
          placeholder="Например, здоровье"
          :options="state.filteredNicheList1"
          :canAcceptAnyValue="true"
          only-input
          :error="errors.error"
      />

      <FormOptionSelect
          class="select"
          name="niche2"
          placeholder="Например, финансы"
          :options="state.filteredNicheList2"
          :canAcceptAnyValue="true"
          only-input
          :error="errors.error"
      />

      <FormOptionSelect
          class="select"
          name="niche3"
          placeholder="Например, инфобизнес"
          :options="state.filteredNicheList3"
          :canAcceptAnyValue="true"
          only-input
          :error="errors.error"
      />
    </Card>

    <div style="flex-grow: 1 "/>

    <div
        style="padding: 0 16px; background: #171717; border-top: 1px solid #2B2B2B; position: fixed; bottom: 0; left: 0; right: 0;"
    >
      <Typography
          style="margin: 24px 0 24px 0"
          variant="v14-600"
          center
      >
        Возникли сложности?
        <AppLink
            color="#FF303A"
            @click="openExternalURL(store.state.user.directories.support_chat_link.link)"
        >
          Напишите боту
        </AppLink>
      </Typography>

      <AppButton
          style="margin-top: 8px; margin-bottom: 24px"
          size="large"
          full-width
          @click="onSubmit"
          :loading="isSubmitting"
          :disabled="isSubmitting"
      >
        Продолжить
      </AppButton>
    </div>
  </div>
</template>

<script setup>
import Typography from '@/components/UI/Typography.vue'
import AppButton from '@/components/UI/AppButton.vue'
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import store from '@/store/store.js'
import {onBeforeMount, onMounted, reactive, watch} from 'vue'
import Card from '@/components/UI/Card.vue'
import FormOptionSelect from '@/components/form/FormOptionSelect.vue'
import {appAxios} from '@/axios.js'
import {router} from '@/router/router.js'
import FormError from '@/components/form/FormError.vue'
import AppLink from "@/components/AppLink.vue";
import {openExternalURL} from "@/utils/openExternalURL.js";

const props = defineProps({
  nextStep: {
    type: Function,
    require: true,
  },
  createUserStepIfNotExist: {
    type: Function,
    require: true,
  },
})

const state = reactive({
  isCreated: false,
  nicheList: [],
  filteredNicheList1: [],
  filteredNicheList2: [],
  filteredNicheList3: [],
})

const {handleSubmit, setErrors, errors, values, isSubmitting, setValues} = useForm({
  validationSchema: Yup.object({
    niche1: Yup.string().label('Ниша'),
    niche2: Yup.string().label('Ниша'),
    niche3: Yup.string().label('Ниша'),
    error: Yup.mixed()
        .test('error', 'Заполни хотя бы одно поле', () => {
          return values.niche1 || values.niche2 || values.niche3
        })
  }),
})

watch(values, (values) => {
  const used = {
    [values.niche1]: true,
    [values.niche2]: true,
    [values.niche3]: true,
  }

  state.filteredNicheList1 = state.nicheList.filter(niche => {
    if (values.niche1 === niche.value) {
      return true
    }
    return !used[niche.value]
  })

  state.filteredNicheList2 = state.nicheList.filter(niche => {
    if (values.niche2 === niche.value) {
      return true
    }
    return !used[niche.value]
  })

  state.filteredNicheList3 = state.nicheList.filter(niche => {
    if (values.niche3 === niche.value) {
      return true
    }
    return !used[niche.value]
  })
})

onBeforeMount(() => {
  state.nicheList = [
    ...store.state.user.directories.niche_list.map(niche => {
      return {
        value: niche.id,
        label: niche.title,
      }
    }),
    ...store.state.user.profile.niches.map(userNiche => {
      return {
        value: userNiche.niche.id,
        label: userNiche.niche.title,
      }
    })
  ]
})

onMounted(() => {
  const [p1, p2, p3] = store.state.user.profile.niches
  setValues({
    niche1: p1 ? p1.niche.id : undefined,
    niche2: p2 ? p2.niche.id : undefined,
    niche3: p3 ? p3.niche.id : undefined,
  })
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      setErrors({
        error: null,
      })
    })
  })
})

const onSubmit = handleSubmit(async values => {
  const selectedNiches = []
  if (values.niche1) {
    selectedNiches.push(values.niche1)
  }
  if (values.niche2) {
    selectedNiches.push(values.niche2)
  }
  if (values.niche3) {
    selectedNiches.push(values.niche3)
  }

  const nichesRes = await appAxios.niche.fetchAll()
  const userNiches = nichesRes.data

  const toAdd = []
  const toRemove = []

  selectedNiches.forEach(nicheId => {
    const found = userNiches.find(userNiche => userNiche.niche.id === nicheId)
    if (found) {
      return
    }
    if (nicheId.toString().includes('|isNew|')) {
      toAdd.push({
        title: nicheId.replace('|isNew|', '')
      })
    } else {
      const found = store.state.user.directories.niche_list.find(p => p.id === nicheId)
      if (found) {
        toAdd.push({
          title: found.title,
        })
      }
    }
  })

  userNiches.forEach(userNiche => {
    const found = selectedNiches.find(nicheId => userNiche.niche.id === nicheId)
    if (found) {
      return
    }
    toRemove.push(userNiche)
  })

  const promises = [
    ...toRemove.map(item => appAxios.niche.remove(item.id)),
    ...toAdd.map(item => appAxios.niche.create(item.title)),
  ]

  await Promise.all(promises)
  const res = await appAxios.niche.fetchAll()

  await store.commit('user/setProfile', {
    niches: res.data,
  })
  await router.push({name: 'Profile'})

  await props.nextStep()
})

</script>

<style lang="scss" scoped>

</style>
