export function timeDiff(timeStart, timeEnd, verbose) {
  const timeLeft = (new Date(timeEnd) - new Date(timeStart)) / 1e3

  const h = Math.floor(timeLeft / 3600);
  const m = Math.floor(timeLeft % 3600 / 60);
  const s = Math.floor(timeLeft % 3600 % 60);

  if(verbose) {
    let str = h > 0 ? `${String(h).padStart(2, '0')} ч` : null
    return `${str} ${String(m).padStart(2, '0')} мин`
  }

  return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`
}
