<template>
  <Card class="info" :class="{[variant]: variant}">
    <div class="header">
      <Icon class="icon" width="20" height="20" :icon="require(`@/assets/icons/user_20_search.svg`)"/>

      <Typography variant="subtitle1">
        Ищу
      </Typography>

      <AppButton
          v-if="isEdit"
          class="btn"
          size="small"
          variant="transparent"
          @click="() => router.push({name: 'EditLookingFor'})"
      >
        <Icon
            :icon="lookingFor.length ? require(`@/assets/icons/gray_20_pen.svg`) : require(`@/assets/icons/red_20_add.svg`)"
        />
      </AppButton>
    </div>

    <div v-if="lookingFor.length" class="tags">
      <template v-for="(item) in lookingFor">
        <div
            class="tag"
            @click="() => isActive ? emit('applyForSkill', item.skill) : null"
        >
          <Typography variant="body1" color="#D3D2D2" class="productTitle">
            {{ item.skill.title }}
          </Typography>
          <AppButton
              v-if="isActive"
              variant="transparent"
              size="tiny"
              class="arrayBtn"
              @click="emit('applyForSkill', item.skill)"
          >
            <Icon :icon="require(`@/assets/icons/white_24_arrow.svg`)"/>
          </AppButton>
        </div>
      </template>
    </div>

  </Card>
</template>

<script setup>
import Card from '@/components/UI/Card.vue'
import Icon from '@/components/UI/Icon.vue'
import Typography from '@/components/UI/Typography.vue'
import {reactive} from 'vue'
import AppButton from '@/components/UI/AppButton.vue'
import {router} from '@/router/router'

const emit = defineEmits(['applyForSkill'])

const props = defineProps({
  variant: {
    type: String,
    default: '',
  },
  isActive: {
    type: Boolean,
    default: true,
  },
  lookingFor: {
    type: Array,
    default: [],
  },
  isEdit: {
    type: Boolean,
    default: false,
  }
})

const state = reactive({

})

</script>

<style lang="scss" scoped>

.info {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.light {
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.08);
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  position: relative;

  .btn {
    position: absolute;
    top: -9px;
    right: -9px;
  }

  & > .icon {
    flex-shrink: 0;
  }
}

.tags {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;

  .tag {
    padding: 12px;
    background: #2F2F2F;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    align-items: center;
    gap: 8px;

    & > button {
      flex-shrink: 0;
    }
  }
}

.info.light {
  .tags {
    .tag {
      background: rgba(255, 255, 255, 0.08);
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    }

    & > .tag:last-child {
      //border-bottom: none;
      //padding-bottom: 0;
    }
  }
}

.arrayBtn {
  margin: -8px 0;
}

</style>
