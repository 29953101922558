<template>
  <div class="logo">
    <img src="@/assets/images/logo/white_logo_leadpay_128_58.svg" class="dark" alt="" />
  </div>
</template>

<script>
export default {
  name: "Logo",
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.logo {
  display: inline-flex;
  width: 100%;
  height: 120px;
  align-items: center;
  justify-content: center;
  position: relative;
  &::after {
    content: "";
    width: 100%;
    height: 239px;
    filter: blur(100px);
    transform: translate3d(0, 0, 0);
    background: rgb(207, 46, 46);
    opacity: 0.08;
    position: absolute;
    top: -119px;
    left: 0;
  }
}

img {
  user-select: none;
}
</style>
