<template>
  <div
      class="card"
      :class="{[variant]: variant}"
      @click="profileClick"
  >
    <template v-if="isLoading || !data">
      <div class="content">
        <div class="header">
          <Avatar
              :isLoading="true"
              :size="'medium'"
          />
          <Skeleton
              width="180"
              height="18"
          />
        </div>

        <div class="info">
          <Typography variant="subtitle2">
            <Skeleton
                variant="text"
                width="160"
                height="14"
            />
          </Typography>

          <Skeleton
              v-for="i in 2"
              variant="text"
              :width="String(200 + (i % 2) * 50 + (i * 20))"
              height="12"
          />
        </div>

        <div class="bottom">
          <div v-if="variant!=='small' && !hideActions" class="score">
            <Skeleton
                width="40"
                height="18"
            />
          </div>
          <template v-if="!hideActions">
            <AppButton
                v-if="userContact"
                size="tiny"
                :is-loading="true"
                width="60"
            >
              <Icon :icon="require(`@/assets/icons/white_16_filledStar.svg`)"/>
              {{ userContact.rating }}
            </AppButton>
            <AppButton
                v-else
                size="tiny"
                :is-loading="true"
                full-width
                style="padding-left: 8px;padding-right: 8px;max-width: 166px"
            >
              Добавить в визитницу
            </AppButton>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="backgroundWrapper">
        <div
            class="background"
            :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(data.preview_avatar))}"
        />
      </div>

      <div class="content">
        <div class="header">
          <Avatar
              :image="imagesLinkCache.cache(data.preview_avatar)"
              :size="'medium'"
          />
          <div class="userInfo">
            <typography :variant="variant === 'small' ? 'subtitle2' : 'subtitle1'">
              {{ data.first_name }} {{ data.last_name }}
            </typography>
            <UserSpecializations
                :specializations="data.specializations"
                oneLine
            />
          </div>
        </div>

        <div class="info">
          <UserProfessions
              :professions="data.professions"
              oneLine
          />

          <Typography
              class="aboutMeText"
              variant="subtitle2"
              color="#D3D2D2"
              preLine
              :style="{
                lineClamp: 4,
                maxHeight: 72,
              }"
          >
            {{ data.about_me }}
          </Typography>
        </div>

        <div class="bottom">
          <div v-if="variant!=='small'" class="score">
<!--            <Icon v-if="data.rating" :icon="require(`@/assets/icons/gray_16_star.svg`)"/>-->
<!--            <Typography variant="body1">-->
<!--              {{ data.rating }}-->
<!--            </Typography>-->
            <UserLocationAndAge
                v-if="variant!=='small'"
                :city="data.city"
                :birthDate="data.birth_date"
            />
          </div>

          <template v-if="!hideActions">
            <AppButton
                v-if="userContact && userContact.contact_user"
                variant="gray"
                size="tiny"
                @click.stop="$emit('reEvaluate', userContact)"
                :loading="isBtnLoading"
                :disabled="isBtnLoading"
            >
              Удалить
            </AppButton>
            <AppButton
                v-else
                :full-width="variant==='small'"
                variant="gray"
                size="tiny"
                @click.stop="addContact(data)"
                :loading="isBtnLoading"
                :disabled="isBtnLoading"
            >
              Добавить
            </AppButton>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import Avatar from '@/components/profile/Avatar.vue'
import Typography from '@/components/UI/Typography.vue'
import AppButton from '@/components/UI/AppButton.vue'
import {urlToCssURL} from '@/utils/urlToCssURL'
import Icon from '@/components/UI/Icon.vue'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import Skeleton from '@/components/UI/Skeleton.vue'
import UserLocationAndAge from '@/components/UserLocationAndAge.vue'
import UserProfessions from '@/components/UserProfessions.vue'
import UserSpecializations from '@/components/UserSpecializations.vue'

const emit = defineEmits(['reEvaluate', 'addContact'])

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String, // default, small
    default: 'default'
  },
  type: {
    type: String,
    default: 'default'
  },
  hideActions: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object,
  },
  userContact: {
    // type: Object,
  },
  profileClick: {
    type: Function,
  },
  isBtnLoading: {
    type: Boolean,
    default: false,
  }
})

async function addContact(data) {
  emit('addContact', data)
}

</script>

<style lang="scss" scoped>

.card {
  padding: 16px;
  border-radius: 20px;
  border: 1px solid #FFFFFF14;
  background: #222222;
  user-select: none;
  position: relative;
  height: 256px;

  .backgroundWrapper {
    position: absolute;
    border-radius: 20px;
    z-index: 1;
    top: 0;
    left: 0;
    width: calc(100% - 0px);
    height: calc(100% - 0px);
    display: flex;
    overflow: hidden;

    .background {
      background-size: cover;
      filter: blur(50px);
      transform: translate3d(0, 0, 0);
      opacity: .12;
      flex-grow: 1;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 14px;
  position: relative;
  z-index: 2;
  height: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 14px;
  flex-grow: 1;
}

.aboutMeText {
  line-clamp: 4;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 72px;
}

.header {
  display: flex;
  align-items: center;
  gap: 16px;

  .userInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: calc(100% - 80px);
  }
}

.score {
  display: flex;
  align-items: center;
}

.bottom {
  height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card.small {
  padding: 16px;
  border-radius: 16px;
  gap: 16px;
  height: 224px;

  .backgroundWrapper {
    border-radius: 16px;
  }

  .header {
    gap: 14px;
  }
}

</style>
