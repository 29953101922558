<template>
  <div class="page main_container">

    <div class="grow"/>

    <Icon
        style="margin-top: 24px;flex-shrink: 0"
        width="278"
        height="296"
        :icon="require(`@/assets/images/system/new_version_available.svg`)"
    />

    <typography
        variant="subtitle"
        style="margin-bottom: 8px; margin-top: 32px"
        center
    >
      Ура! У нас вышла новая версия платформы!
    </typography>

    <typography
        variant="body1"
        center
    >
      Обновление происходит мгновенно — просто нажми на <span style="font-weight: 700">«Обновить»</span>
    </typography>

    <div class="grow"/>

    <AppButton
        full-width
        style="margin-top: 24px"
        @click="refresh"
    >
      Обновить
    </AppButton>
  </div>
</template>

<script setup>
import Icon from '@/components/UI/Icon.vue'
import AppButton from '@/components/UI/AppButton.vue'
import Typography from '@/components/UI/Typography.vue'
import {useRoute} from 'vue-router'

const location = window.location

const route = useRoute()

async function refresh() {
  const registrations = await navigator.serviceWorker.getRegistrations()
  for (const registration of registrations) {
    await registration.unregister();
  }
  window.location.reload(true)
}

</script>

<style lang="scss" scoped>
.page {
  min-height: 100dvh;
  max-height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  padding: 36px;
}

.grow {
  flex-grow: 1;
}
</style>
