<template>
  <div
      class="modal"
      :class="isShown ? 'modal_active' : ''"
      ref="modalRef"
      @click="check"
  >
    <div class="modal_in">
      <div class="closeButtonOutside">
        <AppButton
            size="large"
            variant="secondary"
            @click="emit('close')"
        >
          <Icon :icon="require(`@/assets/icons/white_24_cross.svg`)"/>
        </AppButton>
      </div>

      <transition name="fade">
        <div
            v-if="roulette.course"
            class="content won"
        >
          <div class="stars"/>

          <Typography class="modalTitle" variant="title">
            Поздравляем!
          </Typography>

          <Typography class="modalDescription" variant="text1" color="#FFF">
            Твой выигрыш:
          </Typography>

          <div class="item">
            <div class="imgWrapper">
              <div
                  class="img"
                  :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(roulette.course.preview_image))}"
              />
            </div>
            <div class="info">
              <div class="title">
                {{ roulette.course.title }}
              </div>

              <Typography v-if="roulette.promo_ends_at" class="nextPlay" variant="text1" color="#fff">
                <Icon class="icon" width="16" height="16" :icon="require(`@/assets/icons/white_16_clock.svg`)"/>
                {{ timeDiff(state.timeNow, roulette.promo_ends_at) }}
              </Typography>
            </div>
          </div>

          <Typography class="nextTime" center variant="text1" color="#FFF">
            Следующая прокрутка через {{ timeDiff(state.timeNow, roulette.promo_ends_at, true) }}
          </Typography>

          <AppButton
              variant="white"
              size="large"
              full-width
              color="#000"
              @click="() => router.push({name: 'StudyCourse', params: {userCourseId: roulette.id}})"
          >
            Перейти к материалу
          </AppButton>

        </div>
      </transition>
      <transition name="fade">
        <div
            v-if="!roulette.course"
            class="content"
        >
          <div class="stars"/>

          <Typography class="modalTitle" variant="title">
            Крути Колесо Фортуны и забирай подарок!
          </Typography>

          <Typography class="modalDescription" variant="text1" color="#FFF">
            Держим за тебя кулачки!
          </Typography>

          <AppButton
              variant="gold"
              size="large"
              style="padding: 0 48px;"
              @click="startSpinning"
              :disabled="state.isLocked"
          >
            Крутить
          </AppButton>

          <WheelSpin
              :isSpin="state.isSpin"
              @stopped="stoppedSpinning"
          />

        </div>
      </transition>

      <RouletteConfetti
          :isConfetti="state.isConfetti"
      />
    </div>
  </div>
</template>

<script setup>
import {onBeforeMount, onMounted, onUnmounted, reactive, ref, watch} from 'vue'
import AppButton from '@/components/UI/AppButton.vue'
import Icon from '@/components/UI/Icon.vue'
import {useModalScrollingFix} from '@/hooks/useModalScrollingFix'
import WheelSpin from '@/views/HomeView/roulette/WheelSpin.vue'
import Typography from '@/components/UI/Typography.vue'
import RouletteConfetti from '@/views/HomeView/roulette/RouletteConfetti.vue'
import {appAxios} from '@/axios'
import {urlToCssURL} from '@/utils/urlToCssURL'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import {timeDiff} from '@/utils/timeLeft'
import {router} from '@/router/router'
import {wait} from '@/utils/wait'

const modalScrollingFix = useModalScrollingFix()
const modalRef = ref(null)
const emit = defineEmits(['close', 'won'])

const props = defineProps({
  closeOnClickAway: {
    type: Boolean,
    default: true,
  },
  isShown: {
    default: false,
  },
  roulette: {
    default: Object,
  }
})

const promiseResolverRef = ref()

const state = reactive({
  isLocked: false,
  isSpin: false,
  isConfetti: false,
  interval: null,
  timeNow: Date.now(),
})

watch(() => props.isShown, (value) => {
  if (value) {
    modalScrollingFix.open()
  } else {
    modalScrollingFix.close()
  }
})

onMounted(() => {
  state.interval = setInterval(() => {
    state.timeNow = Date.now()
  }, 1e3)
})

onUnmounted(() => {
  clearInterval(state.interval)
})

function check(event) {
  if (event.target === modalRef.value && props.closeOnClickAway) {
    emit('close')
  }
}

function stoppedSpinning() {
  console.log('resolve')
  promiseResolverRef.value()
}

async function startSpinning() {
  if (state.isLocked === true) {
    return
  }
  state.isConfetti = false
  state.isLocked = true

  const promise = new Promise(res => {
    promiseResolverRef.value = res
  })

  try {
    state.isSpin = true
    const [playRes] = await Promise.all([
      appAxios.roulette.play(),
      wait(2e3),
    ])
    state.isSpin = false
    await promise
    state.isConfetti = true
    emit('won', playRes.data)
  } catch (e) {
    console.error(e)
  }
  state.isLocked = false
  state.isSpin = false
}

</script>

<style lang="scss" scoped>
.modal {
  height: 100%;
  width: 100%;
  backdrop-filter: blur(13px);
  transform: translate3d(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease, z-index 0.5s ease;
  padding: 16px;

  .modal_in {
    margin-top: 56px;
    position: relative;
    width: 100%;
    //height: 375px;
    height: 360px;
    max-width: 375px;
    border-radius: 24px;
    transform: translateY(500px);
    transition: transform 0.5s ease, opacity 0.5s ease, z-index 0.5s ease;
    background: linear-gradient(1turn, #f7b733, #fc5e1a);
  }

  .content {
    padding: 12px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.modal_active {
  opacity: 1;
  z-index: 9999;

  .modal_in {
    transform: translateY(0);
  }
}

.closeButtonOutside {
  position: absolute;
  right: 0;
  top: -56px;
}

.stars {
  background: url('@/assets/images/roulette/rouletteModalModalStars.svg') center center no-repeat;
  width: 100%;
  height: 55px;
  position: absolute;
  top: 200px;
}

.modalTitle {
  margin-top: 12px;
  margin-bottom: 8px;
  text-align: center;
}

.modalDescription {
  text-align: center;
  margin-bottom: 24px;
  padding: 0 24px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item {
  width: 100%;
  display: flex;
  align-items: stretch;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: rgba(0, 0, 0, 0.08);
  padding: 12px;
  gap: 12px;
  margin-bottom: 12px;

  .imgWrapper {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.12);
    padding: 4px;
  }

  .img {
    width: 72px;
    height: 72px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.info {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #fff;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.nextPlay {
  min-width: 96px;
  max-width: 96px;
  display: flex;
  gap: 4px;
  justify-content: left;
  align-items: center;
  padding: 4px 12px;
  border-radius: 37px;
  background: #CF2E2E;
}

.nextTime {
  margin: 12px 0 24px;
}

.content.won {
  justify-content: space-between;

  .stars {
    top: -12px;
  }
}

</style>
