import {sleep} from "@/utils/sleep.js";

const rawYandexWebVisorNum = import.meta.env.VUE_APP_YANDEX_WEBVISOR
export const yandexWebVisorNum = rawYandexWebVisorNum ? Number(import.meta.env.VUE_APP_YANDEX_WEBVISOR) : null

export function yandexMetrika(a, b) {
  void async function () {
    if (!yandexWebVisorNum) {
      return
    }
    while (!window.ym) {
      await sleep(1e3)
    }
    window.ym(yandexWebVisorNum, a, b)
  }()
}
