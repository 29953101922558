<template>
  <BottomDrawer
      :is-shown="true"
      style="visibility: visible"
  >
    <div style="padding: 0 16px">
      <Typography variant="subtitle" center style="margin-bottom: 8px">
        Нужно войти в профиль
      </Typography>

      <Typography variant="body1" center>
        Это займёт несколько секунд, вход и регистрация по коду из СМС
      </Typography>
    </div>

    <AppButton
        style="margin-top: 24px"
        full-width
        @click="() => {
          sessionStorage.setItem('previewAfterOnboarding', JSON.stringify({name: routeName, params: route.params}))
          router.push({name: 'Register'})
        }"
    >
      Зарегистрироваться
    </AppButton>

    <AppButton
        style="margin-top: 12px"
        variant="secondary"
        full-width
        @click="() => {
          sessionStorage.setItem('previewAfterOnboarding', JSON.stringify({name: routeName, params: route.params}))
          router.push({name: 'LogIn'})
        }"
    >
      Войти
    </AppButton>
  </BottomDrawer>
</template>

<script setup>
import {router} from '@/router/router.js'
import Typography from '@/components/UI/Typography.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import AppButton from '@/components/UI/AppButton.vue'
import {useRoute} from 'vue-router'

const props = defineProps({
  routeName: {
    type: String,
    require: true,
  }
})

const sessionStorage = window.sessionStorage
const route = useRoute()
</script>

<style lang="scss" scoped>

</style>
